<template>
  <div class="home-customers-voice">
    <div class="title-cover space-150">
      <img :src="`${rootUrl}/img/title04.png`" alt="お客様の声" />
    </div>
    <p class="center space-50">
      ご利用いただいたお客様の声をご紹介します。高価買取実績が多数あります。
    </p>
    <div class="box-voice-cover space-100">
      <div v-for="(voiceList, voiceListIndex) in voiceListList" class="box-voice" :key="voiceListIndex">
        <UiBoxVoice v-for="(voiceData, voiceDataIndex) in voiceList" :title="voiceData.title"
          :subTitle="voiceData.subTitle" :detail="voiceData.detail" :target="voiceData.target" class="box"
          :key="voiceDataIndex" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBoxVoice from "@/components/Ui/BoxVoice.vue";
import CategoryConsts from "@/consts/CategoryConsts";

export default {
  name: "home-if-you-buy",
  components: {
    UiBoxVoice,
  },
  props: {
    categoryId: String
  },
  data() {
    return {
      // Consts
      CategoryConsts: CategoryConsts,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    voiceListList() {
      let voiceListList = [];

      // 
      switch (this.categoryId) {
        // 
        case CategoryConsts.ID_CLASSIC_RECORD:
        case CategoryConsts.ID_JAZZ_RECORD:
        case CategoryConsts.ID_WJ_MUSIC_RECORD:
        case CategoryConsts.ID_OPEN_REEL:
        case CategoryConsts.ID_AUDIO_CD:
        case CategoryConsts.ID_AUDIO_PARTS:
        case CategoryConsts.ID_AUDIO:
        case CategoryConsts.ID_BOOKS:
        case CategoryConsts.ID_OTHERS:
          voiceListList = [
            [
              this.getVoiceData(this.categoryId, '1'),
              this.getVoiceData(this.categoryId, '2'),
            ],
            [
              this.getVoiceData(this.categoryId, '3'),
              this.getVoiceData(this.categoryId, '4'),
            ],
          ];
          break;

        default:
          voiceListList = [
            [
              this.getVoiceData(CategoryConsts.ID_CLASSIC_RECORD, '5'),
              this.getVoiceData(CategoryConsts.ID_JAZZ_RECORD, '5'),
            ],
            [
              this.getVoiceData(CategoryConsts.ID_OPEN_REEL, '3'),
              this.getVoiceData(CategoryConsts.ID_OPEN_REEL, '4'),
            ],
            [
              this.getVoiceData(CategoryConsts.ID_CLASSIC_RECORD, '1'),
              this.getVoiceData(CategoryConsts.ID_CLASSIC_RECORD, '2'),
            ],
            [
              this.getVoiceData(CategoryConsts.ID_CLASSIC_RECORD, '3'),
              this.getVoiceData(CategoryConsts.ID_CLASSIC_RECORD, '4'),
            ],
            [
              this.getVoiceData(CategoryConsts.ID_JAZZ_RECORD, '1'),
              this.getVoiceData(CategoryConsts.ID_JAZZ_RECORD, '2'),
            ],
            [
              this.getVoiceData(CategoryConsts.ID_JAZZ_RECORD, '3'),
              this.getVoiceData(CategoryConsts.ID_JAZZ_RECORD, '4'),
            ],
            [
              this.getVoiceData(CategoryConsts.ID_OPEN_REEL, '1'),
              this.getVoiceData(CategoryConsts.ID_OPEN_REEL, '2'),
            ],
          ];
          break;
      }

      // 
      return voiceListList;
    },
  },
  methods: {
    getVoiceData(categoryId, categoryVoiceId) {
      // 
      const voiceList = [
        // クラシックレコード
        {
          categoryId: CategoryConsts.ID_CLASSIC_RECORD,
          categoryVoiceId: '1',
          title: "引っ越しのため困っていましたが、簡単でした",
          subTitle: "クラシックのレコードを約800点、155,000円で買取させて頂きました",
          detail: "引っ越しするため、クラシック好きの父親が集めていたレコードのコレクションを処分することにしました。インターネットで調べて、色んなお店に電話しましたが、クラシックの買取はしていないと言われてしまいました。<br>何とかならないものかと相談したところ、他のお店からリアリーグッドを紹介してもらいました。電話したら、すぐ買取に来てくれて丁寧に扱ってくれた上に、買取もしっかりやってくれました。父も喜んでいます。ありがとうございました。",
          target: "三重県 / 30代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_CLASSIC_RECORD,
          categoryVoiceId: '2',
          title: "とても丁寧に<br>説明してくださいました",
          subTitle: "クラシックのレコードを約150枚、25,000円で買取させて頂きました",
          detail: "家の物置から昔祖父が聞いていたクラシックのレコードが段ボールに入っているのを見つけ、プレーヤーもなく聞くことが出来ないので買い取ってもらうことにしました。レコードの知識がなく売れるものかどうかも分からなかったので、電話で相談したらとても丁寧に説明してくださいました。すぐに片づけてしまいたかったので宅配でお願いしましたが、送ってからすぐに査定して連絡をくれて、振り込みしてもらうまでとってもスピーディーでした。",
          target: "愛知県 / 40代 / 女性",
        },
        {
          categoryId: CategoryConsts.ID_CLASSIC_RECORD,
          categoryVoiceId: '3',
          title: "専門的な知識がある",
          subTitle: "クラシックのレコードを約350枚、122,500円で買取させて頂きました",
          detail: "レコードのコレクションを整理しているが、査定スタッフはクラシックの知識があり、専門店と謳うだけあり電話で細かい相談が出来た。回答も明瞭で安心感があり、定期的に利用したいと思う。",
          target: "大阪府 / 70代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_CLASSIC_RECORD,
          categoryVoiceId: '4',
          title: "一枚一枚丁寧に<br>見てくれたことに感動",
          subTitle: "クラシックのレコードを約40枚、2,800円で買取させて頂きました",
          detail: "聞かなくなったレコードを処分しようと思い近くの店へ持って行ったところ、まとめてではなく一枚一枚丁寧に見てくれたことに感動を覚えこうして文字にしています。CDも良いとのことなので、また聞かなくなったら持っていきます。",
          target: "愛知県/ 60代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_CLASSIC_RECORD,
          categoryVoiceId: '5',
          title: "丁寧な対応でした",
          subTitle: "オールジャンルレコード約500点、125,000円で買取させて頂きました",
          detail: "聞かなくなったレコードの処分に困り相談したところ、直接見に来てくれました。現在の価値やレコードの状況なども事細かく説明して頂き、その場でレコードの事前見積もりを提示して下さいました。想定の範囲の金額でしたので 引き取ってもらいました。後日最終的な買取額をご連絡いただきましたが見積もってもらった金額より高い結果で良かったです。",
          target: "岐阜県 / 60代 / 男性",
        },

        // ジャズレコード
        {
          categoryId: CategoryConsts.ID_JAZZ_RECORD,
          categoryVoiceId: '1',
          title: "ジャズ以外にも色々ありましたが、まとめて買い取ってくれました。",
          subTitle: "ジャズレコードを含む300枚を96,000円で買取させて頂きました",
          detail: "家にあったジャズのレコード100枚くらいと、その他邦楽や洋楽のレコードをまとめて買い取ってもらいました。<br>引っ越しが近く、急いでいると伝えたら翌日には引き取りに来てくれました。棚にしまったままだったのですが、全てお店の方がやってくれたので助かりました。",
          target: "愛知県 / 50代 / 女性",
        },
        {
          categoryId: CategoryConsts.ID_JAZZ_RECORD,
          categoryVoiceId: '2',
          title: "細かく専門的な査定で、<br>安心してお願いできました。",
          subTitle: "ジャズのレコード230枚を158,000円で買取させて頂きました",
          detail: "大事に集めてきたレコードでオリジナル盤など貴重なものもあったので、手放すのを迷っていましたが、出張引き取りの際にその場でお見積りをしていただけました。どのレコードが何故価値があるかも丁寧に説明していただき、ジャズに精通していることが良くわかりました。レコードの価値の分かる専門店なので安心してお願いできました。",
          target: "岐阜県 / 70代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_JAZZ_RECORD,
          categoryVoiceId: '3',
          title: "電話で丁寧に説明してくれたので<br class='pc-only'>良かったです。",
          subTitle: "ジャズのレコード80枚を50,000円で買取させて頂きました",
          detail: "父の遺品で、レコードのことは何もわからないのでお電話で相談しました。タイトルやレコードの番号などを伝えたら、おおよその値段を見積もってくれました。買取してもらうのが初めてで、私は少し不安だったのですが、丁寧に説明してくれたのでよかったです。",
          target: "滋賀県 / 30代 / 女性",
        },
        {
          categoryId: CategoryConsts.ID_JAZZ_RECORD,
          categoryVoiceId: '4',
          title: "その場ですぐお見積もり<br>してくれました",
          subTitle: "ジャズのレコード20枚を7,800円で買取させて頂きました",
          detail: "ジャズの国内盤中心に20枚くらいを店舗に持ち込んで買取してもらいました。ジャケットも綺麗で帯などもついていたので思ったより値段を付けてもらえました。その場ですぐお見積もりしてくれて、その日のうちに支払いまでしてくださったので助かりました。",
          target: "愛知県 / 50代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_JAZZ_RECORD,
          categoryVoiceId: '5',
          title: "その場ですぐお見積もり<br>してくれました",
          subTitle: "ジャズのレコード20枚を7,800円で買取させて頂きました",
          detail: "ジャズの国内盤中心に20枚くらいを店舗に持ち込んで買取してもらいました。ジャケットも綺麗で帯などもついていたので思ったより値段を付けてもらえました。その場ですぐお見積もりしてくれて、その日のうちに支払いまでしてくださったので助かりました。",
          target: "愛知県 / 50代 / 男性",
        },

        // 洋楽・邦楽レコード
        {
          categoryId: CategoryConsts.ID_WJ_MUSIC_RECORD,
          categoryVoiceId: '1',
          title: "丁寧な対応と査定で、<br>安心してお願い出来ました",
          subTitle: "ロックのレコード500枚を220,000円で買取させて頂きました。",
          detail: "レコード買取のサービスは初めて利用しましたが、驚くほどスムーズで効率的でした。丁寧な査定と迅速な支払いに感動しました。また、親切なスタッフの方々が私の質問に快く答えてくれたので、安心してお取引できました。大変満足しています！",
          target: "愛知県 / 60代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_WJ_MUSIC_RECORD,
          categoryVoiceId: '2',
          title: "車での持ち込み、その場で<br>すぐお見積もりしてくれました。",
          subTitle: "邦楽のレコード100枚を18,000円で買取させて頂きました",
          detail: "私の手元には不要なレコードがたくさんありましたが、どこに売ればいいのか迷っていました。幸いなことにリアリーグッドさんが近くにあることを知り、利用してみたところ、素晴らしい価格で買い取っていただけました。駐車券サービスがあり駐車場代もかかりませんでした。",
          target: "岐阜県 / 40代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_WJ_MUSIC_RECORD,
          categoryVoiceId: '3',
          title: "いくつかの業者を試しましたが、<br>専門的で一番満足できました",
          subTitle: "洋楽・邦楽のレコード50枚を28,000円で買取させて頂きました。",
          detail: "レコード買取に関してはいくつかの業者を試しましたが、こちらは専門的で一番満足できました。査定の基準が明確で、公正な価格を提示してくれました。正直なところ自信のないレコードもありましたが、ちゃんと値段をつけてくれました。信頼できるお店に出会えて良かったです！",
          target: "神奈川県 / 30代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_WJ_MUSIC_RECORD,
          categoryVoiceId: '4',
          title: "大切な思い出を共有できた事が<br>とても嬉しかった。",
          subTitle: "洋楽のレコード800枚を400,000円で買取させて頂きました",
          detail: "私のレコードコレクションは数十年にわたって育まれたもので、手放すことは寂しかったですが、一度見積りに来てくれるというので出張買取を頼みました。手早く見積もりを出していただき、金額も想定以上でしたので、そのまま買取を依頼しました。<br>また、スタッフの方々が私の思い入れのあるアーティストやアルバムについて熱心に話してくれたので、大切な思い出を共有できたことが嬉しかったです。",
          target: "愛知県 / 70代 / 男性",
        },

        // オープンリール
        {
          categoryId: CategoryConsts.ID_OPEN_REEL,
          categoryVoiceId: '1',
          title: "オープンリールの専門店は<br>少ないので相談しやすかった",
          subTitle: "ミュージックテープ20点、空リールを５点を28,000円で買取させて頂きました",
          detail: "ネットでオープンリールテープの買取をしている店舗を探したら、リアリーグッドさんのホームページを見つけ、専門店とのことだったので依頼しました。<br>近くのリサイクルショップや音楽店では買取してもらえなかったので、値段もしっかりつけていただけて良かったです。",
          target: "愛知県 / 50代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_OPEN_REEL,
          categoryVoiceId: '2',
          title: "レコードの出張引取の時に<br>一緒に買取ってくれました",
          subTitle: "ミュージックテープ20点、空リールを５点を28,000円で買取させて頂きました",
          detail: "祖父の遺品のレコードの出張買取に来てもらった時に、どういうものかわからず処分に困っていると伝えたら丁寧に説明してくださって、一緒に引き取ってもらうことにしました。<br>思ったより値段がついたので驚きました。",
          target: "三重県 / 30代 / 女性",
        },
        {
          categoryId: CategoryConsts.ID_OPEN_REEL,
          categoryVoiceId: '3',
          title: "使える分からず、処分も考えましたが",
          subTitle: "オープンリールデッキ、付属品など5点、40,000円で買取させて頂きました",
          detail: "長いこと使用していなかったオープンデッキがあり、故障している可能性が高かったため処分に悩んでいました。ネットで調べたところ、リアリーグッドさんの買取リストに載っていた機種だったため電話で相談してみました。近くだったこともあり、直ぐに出張買取に来て下さいました。動作テストをして頂き、一部動作不良があり減額がありましたが、十分な金額を提示して頂き、安心、納得の買取でした。",
          target: "愛知県/ 70代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_OPEN_REEL,
          categoryVoiceId: '4',
          title: "まとめて買取ってもらいました",
          subTitle: "オープンリールテープからその他35点、22,000円で買取させて頂きました",
          detail: "オープンリールで使用するものか分からないものもありましたが、先に写真を撮りメールを送ったところ、丁寧に説明して頂き、すべて値段もつくということだったので送りました。カセットテープなど一部違うものも含まれていたようでしたが、すべて買取ってもらうことが出来て良かったです。",
          target: "長崎県/ 50代 / 女性",
        },

        // オーディオCD/高音質CD
        {
          categoryId: CategoryConsts.ID_AUDIO_CD,
          categoryVoiceId: '1',
          title: "ジャンルを問わず、CDを一括で買い取ってくれました",
          subTitle: "ジャズ・邦楽・洋楽を含むCD150枚を20,000円で買取させて頂きました",
          detail: "家の整理中に、昔集めていた邦楽や洋楽のCDが出てきました。宅配買取でCDの段ボールを送ってもらい、簡単に依頼できました。一部は古い作品で状態が心配でしたが、古いCDでも人気があるものがあり、問題なく査定してもらえました。",
          target: "愛知県 / 50代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_AUDIO_CD,
          categoryVoiceId: '2',
          title: "長年集めたコレクションをまとめて依頼",
          subTitle: "ジャズ、クラシックのCD1,500枚を650,000円で買取させて頂きました",
          detail: "事前に電話で相談した際、現地で先に大まかな見積もりを提示してから依頼するか判断してくださいと説明があり、安心して出張買取を依頼できました。実際、概算の見積もり額もよく、更に最終的な金額は、状態を考慮してもらい、上乗せをしてもらえました。クラシックやジャズの音楽の話が通じるところも印象が良かったです。",
          target: "愛知県 / 60代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_AUDIO_CD,
          categoryVoiceId: '3',
          title: "大切に扱って頂けるとのことで依頼",
          subTitle: "クラシックのCD600枚を218,000円で買取させて頂きました",
          detail: "クラシック音楽が好きで、熱心に集めていた故人のCDを依頼しました。どのようなもので、どのような価値があるか、価値が分からない私にも丁寧に説明してくださいました。大切に扱って頂けるとのことで、専門店に依頼して良かったです。",
          target: "愛知県 / 60代 / 女性",
        },
        {
          categoryId: CategoryConsts.ID_AUDIO_CD,
          categoryVoiceId: '4',
          title: "高音質のCDの価値をしっかり査定してもらえる",
          subTitle: "高音質のオールジャンルCD20枚を48,000円で買取させて頂きました",
          detail: "オーディオの趣味で集めている音質の良いCDを時折店舗に売りに行きます。いつも納得できる金額で安心して依頼できます。",
          target: "愛知県 / 40代 / 男性",
        },

        // カセットテープ その他メディア
        {
          categoryId: CategoryConsts.ID_OTHERS,
          categoryVoiceId: '1',
          title: "DATテープとオープンリールテープをまとめて宅配買取",
          subTitle: "DATテープ約50本を12,000円で買取させて頂きました",
          detail: "自分ではもう使わないと思っていたDATテープやオープンリールが、思いがけず買取できることを知り、驚きました。未使用のものと使用済みのものが混ざっていたので、状態がどうなるか心配でしたが、丁寧に整理する方法を教えていただき、安心して送りました。買取後、思ったより高く評価してもらえたことにとても喜んでいます。不要になったものが無駄にならず、ちゃんと評価してもらえて本当に良かったです。",
          target: "福岡県 / 70代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_OTHERS,
          categoryVoiceId: '2',
          title: "終活の一環で眠っていたカセットテープを整理",
          subTitle: "未使用のカセットテープ約150本を120,000円で買取させて頂きました",
          detail: "終活を進める中で、押し入れに眠っていた未使用のカセットテープを整理することにしました。電話での相談後、宅配買取をお願いしましたが、対応も非常にスムーズで安心して依頼することができました。現地での査定も丁寧で、想定以上（当時の購入価格より高い？）の価格で買取していただき、大変満足しています。",
          target: "大阪府 / 60代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_OTHERS,
          categoryVoiceId: '3',
          title: "遺品整理で見つかったMDとカセットテープを買取依頼",
          subTitle: "使用済みのMDとカセットテープ約500本を28,000買取させて頂きました",
          detail: "遺品整理をしていると、棚に大量に積まれたMDとカセットテープを発見しました。録音済でラベルも貼ってあり、もう売れないだろうと思っていたのですが、買取を依頼してみたところ、予想外に買取可能と判断され、驚きました。査定の際も丁寧に扱っていただき、無駄になることなく買取してもらえたことにとても感謝しています。思い出の品を処分することなく、価値を見いだしてもらえたので、心が軽くなりました。",
          target: "神奈川県 / 50代 / 女性",
        },
        {
          categoryId: CategoryConsts.ID_OTHERS,
          categoryVoiceId: '4',
          title: "未使用のカセットテープを高く買い取ってもらいました",
          subTitle: "未使用のカセットテープを4本を50,000円で買取させて頂きました",
          detail: "自分の持っているカセットテープが今では貴重なものだと知り、驚きました。思い切って査定を依頼したところ、高価買取してもらえることが分かり、嬉しかったです。実は他店ではあまり良い評価をもらえなかったのですが、こちらでは予想以上の金額で買取していただき、非常に満足しています。自分の持っていたカセットテープがこんなに価値があるなんて、まさに驚きでした。",
          target: "愛知県 / 50代 / 男性",
        },

        // 真空管 アクセサリー オーディオトランス
        {
          categoryId: CategoryConsts.ID_AUDIO_PARTS,
          categoryVoiceId: '1',
          title: "遺品の真空管・パーツ類を出張買取で依頼しました",
          subTitle: "真空管・パーツ類をまとめて45,000円で買取させて頂きました",
          detail: "オーディオメーカーに勤めていた家族の遺品を整理する中で、真空管や細かいパーツ類が出てきました。正直、これらがどれほどの価値があるのか分からなかったのですが、出張買取を依頼したところ、担当の方がその場で丁寧に説明してくれたのでとても安心しました。真空管やパーツ類の価値をしっかりと見極めていただき、思っていた以上の金額で買取してもらえたことに大変満足しています。",
          target: "静岡県 / 70代 / 女性",
        },
        {
          categoryId: CategoryConsts.ID_AUDIO_PARTS,
          categoryVoiceId: '2',
          title: "アンプ作りの部品を整理、部屋がスッキリ",
          subTitle: "オーディオ・トランス・真空管・パーツ類をまとめて250,000円で買取させて頂きました",
          detail: "父がアンプ作りを趣味にしていたため、部屋には部品がたくさんあり、収拾がつかない状態でした。しかし、買取を依頼したところ、担当の方がとても手際よく整理してくれ、あっという間に部屋が片付きました。不要になった部品をしっかりと評価してもらい、買取価格にも大変満足しています。部屋がスッキリし、整理もしてもらえて本当に助かりました。",
          target: "愛知県 / 30代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_AUDIO_PARTS,
          categoryVoiceId: '3',
          title: "誠実に対応してくれた点がとても印象的でした",
          subTitle: "オーディオ・ケーブル、周辺アクセサリー類を28,000円で買取させて頂きました",
          detail: "オーディオ機器を整理していると、ケーブルや周辺アクセサリー類がたくさん出てきました。オーディオ本体と一緒に買取を依頼したところ、小さな部品も一つ一つ丁寧に仕分けて査定していただきました。適当にまとめて買取ることなく、誠実に対応してくれた点がとても印象的で、信頼感を持てました。予想以上の価格で買い取ってもらえ、非常に満足しています。",
          target: "岐阜県 / 60代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_AUDIO_PARTS,
          categoryVoiceId: '4',
          title: "大事にしていた真空管をしっかりテストして評価してもらえたので安心しました",
          subTitle: "真空管を10本を330,000で買取させて頂きました",
          detail: "終活の一環で、長年大切に保管していた貴重な真空管を整理することにしました。真空管は非常にデリケートなものなので、取り扱いが心配でしたが、担当者が真空管に精通しており、しっかりテストして評価してもらえたので安心しました。大切にしてきたものだけに、専門店にお願いできて本当に良かったです。思い入れのある品を丁寧に扱ってもらえたことに、深く感謝しています。",
          target: "愛知県 / 70代 / 男性",
        },

        // 音楽書籍
        {
          categoryId: CategoryConsts.ID_BOOKS,
          categoryVoiceId: '1',
          title: "レコードと一緒に書籍を買取してもらいました",
          subTitle: "音楽関連の書籍40冊を14,000円で買取させて頂きました",
          detail: "レコードのコレクションと一緒に、長年集めた書籍も整理することにしました。書籍は今まで価値があるか分からず、どうしてよいか悩んでいたのですが、買取を依頼したところ、レコードだけでなく書籍もしっかりと評価していただきました。思い出の詰まったものを無駄にすることなく、きちんと買取してもらえてとても満足しています。レコードと書籍をまとめてお願いできる点もありがたかったです。",
          target: "大阪府 / 50代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_BOOKS,
          categoryVoiceId: '2',
          title: "クラシック音楽の書籍を買取依頼",
          subTitle: "クラシック音楽の書籍約250冊を48,000円で買取させて頂きました",
          detail: "家族の遺品整理をしている中で、大量のクラシック音楽の書籍が出てきました。どう処分すべきか悩んでいたところ、買取を依頼してみることにしました。書籍はすべて状態が良く、買取可能だということで、予想以上の価格で引き取ってもらえたことに驚きました。クラシック音楽に詳しいスタッフの方が丁寧に対応してくれ、思い出の品を大切に扱っていただけたので安心しました。",
          target: "愛知県 / 60代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_BOOKS,
          categoryVoiceId: '3',
          title: "音楽雑誌も丁寧に査定してくれました",
          subTitle: "音楽雑誌諸々約400冊を22,000で買取させて頂きました",
          detail: "長年集めていた音楽雑誌が約20年分もあり、整理が大変でした。どこで処分するか悩んでいたところ、専門店ということで買取をお願いすることにしました。査定の際、値段以上に大切に取り扱ってもらえたことが一番嬉しかったです。担当者が一つ一つ丁寧に見てくれ、長年の思い出を無駄にすることなく、しっかりと評価していただけたことに感謝しています。適切な取り扱いと誠実な対応に満足しています。",
          target: "愛知県 / 50代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_BOOKS,
          categoryVoiceId: '4',
          title: "大量でも手間なくお願いできました",
          subTitle: "オーディオ関連の書籍約500冊を88,000円で買取させて頂きました",
          detail: "長年の定期購読でたまった雑誌やオーディオ関連の書籍がかなりの量になり、整理が大変でした。重い本を持ち運ぶのが難しかったため、出張買取をお願いしました。担当者がわざわざ来てくれ、手間なくスムーズに買取を進めていただけました。また、思った以上の良い値段がついて、専門店にお願いして本当に良かったと感じています。大切な品々を丁寧に扱ってもらえたことに満足しています。",
          target: "岐阜県 / 60代 / 男性",
        },

        // オーディオ
        {
          categoryId: CategoryConsts.ID_AUDIO,
          categoryVoiceId: '1',
          title: "オーディオ一式をレコード・CDと一緒にまとめて買取してもらいました",
          subTitle: "オーディオ一式、280,000円で買取させて頂きました",
          detail: "家族の遺品整理をしている中で、オーディオ一式とレコード・CDが残っていました。重いものが多く、どこに頼めばよいか悩んでいたところ、出張買取をお願いできることを知り、依頼しました。担当者が自宅まで来てくれ、丁寧に査定し、全てまとめて買い取ってもらうことができました。オーディオが思った以上の金額で買取してもらえたので、専門店に頼んで本当に良かったです。",
          target: "京都府 / 60代 / 女性",
        },
        {
          categoryId: CategoryConsts.ID_AUDIO,
          categoryVoiceId: '2',
          title: "終活でAVルームのもの一式を出張買取依頼",
          subTitle: "AVルームのオーディオ機器や関連品一式を180,000で買取させて頂きました",
          detail: "終活で、AVルームにあるオーディオ機器や関連品を処分することにしました。大きな機器が多く、運び出しが大変だったので、出張買取を依頼しました。3名で自宅まで来て、丁寧に査定・運び出しをしてくれ、スムーズに買取を進めていただけました。予想以上の金額で買取してもらい、満足しています。終活の一部として無駄なく整理でき、専門店にお願いして本当に良かったです。",
          target: "愛知県 / 60代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_AUDIO,
          categoryVoiceId: '3',
          title: "処分代がかかるのではと思っていたものが、予想外の高値で買取してもらえた",
          subTitle: "オーディオ類一式90.000円で買取させて頂きました",
          detail: "長年使っていなかったオーディオ類がいくつかあり、ほぼ壊れていたので価値がないと思っていました。しかし、買取をお願いしたところ、担当者が修理可能かどうかを判断し、適切に説明してくれました。処分代がかかるのではと思っていたものが、予想外の高値で買取していただけたことに驚きました。",
          target: "三重県 / 60代 / 男性",
        },
        {
          categoryId: CategoryConsts.ID_AUDIO,
          categoryVoiceId: '4',
          title: "運び出せない大きなスピーカーを買取依頼",
          subTitle: "大型スピーカー1ペアを100,000円で買取させて頂きました",
          detail: "自宅に大きなスピーカーがあり、重くて運び出せず困っていました。出張買取をお願いしたところ、担当の方が数名で丁寧に運び出しを手伝ってくださり、非常に助かりました。運び出してもらえただけでもありがたかったのですが、さらに思った以上の金額で買取してもらい、非常に満足しています。自分ではどうしようかと悩んでいたので、専門店に頼んで本当に良かったと感じています。",
          target: "岐阜県 / 50代 / 女性",
        },
      ];

      // 
      return voiceList.find(voiceData => voiceData.categoryId === categoryId && voiceData.categoryVoiceId === categoryVoiceId);
    }
  }
};
</script>

<style lang="scss" scoped>
.home-customers-voice {
  padding-bottom: 100px;

  @media screen and (max-width: 768px) {
    padding-bottom: 30px;
  }

  .title-cover {
    img {
      margin-top: 100px;

      @media screen and (max-width: 768px) {
        margin-top: 50px;
        width: 100%;
      }
    }
  }

  >p {
    font-weight: bold;
    margin-bottom: 50px;
  }

  .box-voice {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    .box {
      width: 49%;

      @media screen and (min-width: 581px) and (max-width: 1024px) {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
      }

      @media screen and (max-width: 580px) {
        width: 100%;
      }
    }
  }
}
</style>