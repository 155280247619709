<template>
  <div class="ui-drawer">
    <div
      class="back-gray"
      :class="{ 'back-gray-show': openDrawer === true }"
      @click="switchDrawer"
    ></div>
    <img
      @click="switchDrawer"
      :src="`${rootUrl}/img/menu.png`"
      class="drawer-button"
      alt="ドロワーメニュー"
    />
    <div
      class="drawer-menu"
      :class="{
        'open-drawer': openDrawer === true,
        'close-drawer': openDrawer === false,
      }"
    >
      <div class="drawer-header">
        <img
          @click="switchDrawer"
          :src="`${rootUrl}/img/close.png`"
          class="close-button"
          alt="閉じるドロワーメニュー"
        />
      </div>
      <div class="drawer-body" @click="switchDrawer">
        <div class="drawer-item" @click="top">
          <span>TOP</span>
        </div>
        <div class="drawer-item">
          <router-link to="/" v-scroll-to="`#aboutus`"
            >初めての方へ</router-link
          >
        </div>
        <div class="drawer-item">
          <router-link to="/" v-scroll-to="`#buyback`">買取情報</router-link>
        </div>
        <div class="drawer-item">
          <p>買取品目</p>
          <div v-for="(categoryData, index) in categoryList" :key="index" class="drawer-item child">
            <router-link
              :to="`/${categoryData.code}`"
              v-scroll-to="{
                el: '#app',
                container: 'body',
                duration: 750,
                easing: 'ease',
                offset: 0,
              }"
              >{{categoryData.name}}</router-link
            >
          </div>
        </div>
        <div class="drawer-item">
          <p>買取方法</p>

          <div class="drawer-item child">
            <router-link to="/" v-scroll-to="`#delivery`">宅配買取</router-link>
          </div>
          <div class="drawer-item child">
            <router-link to="/" v-scroll-to="`#instore`">出張買取</router-link>
          </div>
          <div class="drawer-item child">
            <router-link to="/" v-scroll-to="`#mobileservice`"
              >店頭買取</router-link
            >
          </div>
        </div>
        <div class="drawer-item">
          <router-link to="/" v-scroll-to="`#voice`">お客様の声</router-link>
        </div>
        <div class="drawer-item">
          <router-link to="/" v-scroll-to="`#qa`">よくある質問</router-link>
        </div>
        <div class="drawer-item">
          <p>店舗情報</p>
          <div v-for="(shopData, index) in shopList" :key="index" class="drawer-item child">
            <router-link :to="`${shopData.path}`" v-scroll-to="`#app`">{{shopData.name}}</router-link>
          </div>
        </div>
        <div class="drawer-item">
          <router-link to="/columns">買取コラム</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UiDrawer",
  data() {
    return {
      openDrawer: false,
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "shopList", 'categoryList']),
  },
  methods: {
    switchDrawer() {
      this.openDrawer = this.openDrawer === false ? true : false;
    },
    top() {
      if (this.$route.path !== "/") {
        //遷移する
        this.$router.push("/");
      } else {
        this.$scrollTo("#app", 500, { easing: "ease" });
        // alert('トップページ');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$width: 80%;
.drawer-button {
  width: 45px;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    width: 40px;
  }
}
.drawer-menu {
  height: 80vh;
  overflow: scroll;
  transition: all 1s 0s ease;
  position: fixed;
  top: 0;
  // left: 0;
  background-color: white;
  // padding: 5px 5px 20px 5px;
  padding: 10px 20px;
  box-sizing: border-box;
  z-index: 999;
  width: 80%;
  @media screen and (min-width: 581px) and (max-width: 820px) {
    width: 50%;
  }
  @media screen and (max-width: 580px) {
    width: 80%;
  }
  // height: 95%;
  .drawer-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    img.close-button {
      width: 30px;
      margin: 15px;
      cursor: pointer;
    }
  }
  .drawer-body {
    // background-color: black;
    .drawer-item {
      text-align: initial;
      border-top: 1px solid #6b6b6b;
      padding: 15px 30px;
      font-family: "Kiwi Maru", serif;
      font-size: 16px;

      // &:last-child {
      //   border-bottom: 1px solid #6b6b6b;
      // }
      a {
        text-decoration: none;
        color: black;
        // font-weight: 700;
        &:hover {
          opacity: 0.5;
        }
      }
    }
    .child {
      padding: 15px 0px 5px 20px;
      border: none;
    }
  }
}
.open-drawer {
  transform: translateX(-$width);
  @media screen and (max-width: 768px) {
    transform: translateX(-76%);
  }
  // right: translateX(-$width);
  // right: -$width;
}
.close-drawer {
  transform: translateX($width);
  right: -$width;
  // right: 0-$width;
}

.back-gray {
  display: none;
  position: fixed;
  z-index: 995;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: gray;
  opacity: 0.4;
}
.back-gray-show {
  display: initial;
}
</style>
