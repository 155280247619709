<template>
  <div class="ctrl-columns">
    <h1>{{ categoryData.name }}の買取コラム一覧</h1>
    <div class="wrap1100">
      <ColumnList :categoryId="categoryId"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ColumnList from "@/components/Ctrl/Columns/ColumnList.vue";

export default {
  name: "ctrl-columns",
  components: {
    ColumnList,
  },
  props: {
    categoryId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    categoryData() {
      return this.$store.getters.categoryData(this.categoryId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
