<template>
  <div class="ctrl-columns">
    <h1>高価買取リスト</h1>
    <div class="wrap1100">
      <MemberList :categoryId="categoryId"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MemberList from "@/components/Ctrl/Members/MemberList.vue";

export default {
  name: "ctrl-columns",
  components: {
    MemberList,
  },
  props: {
    categoryId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    categoryData() {
      return this.$store.getters.categoryData(this.categoryId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
