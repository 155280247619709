<template>
  <div class="columns-column-list">
    <BackImageTitle :categoryId="'1'" :title="`リアリーグッド<br class='sp-only'>買取コラム`" :subTitle="null" />
    <div class="wrap1100">
      <div class="breadcrumb-cover">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">ホーム</el-breadcrumb-item>
          <el-breadcrumb-item>コラム一覧</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="top-text-cover">
        <p>レコード、CDやオープンリール、オーディオ等の買取情報を随時更新中！高価買取中のアイテムや査定のポイントなど、お得な情報をお見逃しなく。</p>
      </div>
      <div class="contents-cover">
        <div v-loading="loading" class="column-list-cover">
          <div v-for="(columnData, index) in columnList" class="column-box" :key="index">
            <div class="thumbnail-cover">
              <img v-if="columnData.thumbnailPath === null" :src="`${rootUrl}/storage/images/default.jpg`" />
              <img v-else :src="`${rootUrl}/storage/${columnData.thumbnailPath}`" />
            </div>
            <div class="text-cover">
              <div class="created-date"><i class="el-icon-time"></i>{{ columnData.jpFormattedCreatedDate }}</div>
              <div class="title">{{ columnData.title }}</div>
              <div class="next-button-cover"><el-button @click="$router.push(`/column-detail/${columnData.id}`)">続きを読む
                  ></el-button></div>
            </div>
          </div>
          <div class="pagination-cover">
            <el-pagination background :page-size="pageSize" :pager-count="pagerCount" :total="totalCount"
              :current-page.sync="currentPage" layout="prev, pager, next" />
          </div>
        </div>
        <ColumnSideBar :text="text" :categoryId="categoryId" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BackImageTitle from "@/components/Ui/BackImageTitle.vue";
import ColumnSideBar from "@/components/Columns/ColumnSideBar.vue";

export default {
  name: 'column-list',
  components: {
    BackImageTitle,
    ColumnSideBar,
  },
  props: {
    text: String,
    categoryId: [null, String],
  },
  data() {
    return {
      // 
      loading: false,
      pageSize: 10,
      pagerCount: 7,
      totalCount: 0,
      currentPage: 1,
      columnList: [],
    }
  },
  computed: {
    ...mapGetters(['rootUrl', 'allCategoryList']),
  },
  watch: {
    currentPage(currentPage) {
      this.getColumnList(currentPage);
    }
  },
  created() {
    this.searchColumnList(1);
  },
  methods: {
    searchColumnList(currentPage) {
      // 
      this.loading = true;

      // 
      this.currentPage = currentPage;

      // 
      this.getColumnList(this.pageSize, this.currentPage);
    },
    getColumnList(pageSize, currentPage) {
      let parameter = ``;
      parameter += `?category_id=${this.categoryId === null ? '' : this.categoryId}`;
      parameter += `&text=${this.text}`;
      parameter += `&visible_only=1`;
      parameter += `&page_size=${pageSize}`;
      parameter += `&current_page=${currentPage}`;
      this.$axios
        .get(`${this.rootUrl}/api/column/get-list${parameter}`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.totalCount = response.data.totalCount;
            this.columnList = response.data.list;
          } else {
            alert("エラーが発生しました。");
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap1100 {
  padding-bottom: 150px;

  .breadcrumb-cover {
    margin-top: 1.5em;
  }

  .top-text-cover {
    padding: 50px;
  }

  .contents-cover {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      // flex-direction: column-reverse;
    }

    .column-list-cover {
      width: 69%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      .column-box {
        display: flex;
        justify-content: space-between;
        padding: 1em;
        border-radius: 3px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
        margin-bottom: 1.5em;

        .thumbnail-cover {
          width: 39%;
        }

        .text-cover {
          width: 59%;

          .created-date {
            color: #aaa;
          }

          .title {
            font-weight: bold;
          }

          .next-button-cover {
            margin-top: 1em;
            text-align: right;
          }
        }
      }

      .pagination-cover {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 3em;
      }
    }

    // .side-bar-cover {
    //   width: 29%;

    //   @media screen and (max-width: 767px) {
    //     width: 100%;
    //   }

    //   .search-box,
    //   .category-box,
    //   .recent-posts-box {
    //     border-radius: 3px;
    //     box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    //     margin-bottom: 2em;
    //   }

    //   .search-box {
    //     padding: 20px;
    //     padding-bottom: 2em;
    //   }

    //   .category-box,
    //   .recent-posts-box {
    //     $brown: #8A4949;

    //     .box-title {
    //       background-color: $brown;
    //       color: white;
    //       padding: 0.3em 1em;
    //       font-weight: bold;
    //     }

    //     .buttons-cover {
    //       padding: 20px;

    //       .category-button {
    //         color: white;
    //         background-color: $brown;
    //         padding: 0.5em 1em;
    //         border: solid 1px #ccc;
    //         border-bottom: none;
    //         font-size: 14px;

    //         &:hover {
    //           cursor: pointer;
    //           opacity: 0.7;
    //         }

    //         &.selected {
    //           color: black;
    //           background-color: white;

    //           .el-icon-success {
    //             color: $brown;
    //           }
    //         }

    //         .el-icon-success {
    //           font-size: 12px;
    //           margin-right: 7px;
    //         }
    //       }

    //       .column-title {
    //         font-weight: bold;
    //         border-top: solid 1px #ccc;
    //         padding: 0.7em;
    //         word-wrap: break-word;

    //         &:last-child {
    //           border-bottom: solid 1px #ccc;
    //         }

    //         &:hover {
    //           cursor: pointer;
    //         }
    //       }
    //     }
    //   }
    // }

  }
}
</style>
