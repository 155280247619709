<template>
  <div class="ui-box-voice">
    <div class="box-voice-cover">
      <div class="title">
        <img :src="`${rootUrl}/img/greenman.png`" alt="お客様" />
        <p v-html="title"></p>
      </div>

      <p v-html="subTitle" class="sub-title"></p>

      <div class="button" @click="openVoice = !openVoice">
        <span>詳細を見る▼</span>
      </div>
      <transition>
        <div class="detail-cover" v-show="openVoice">
          <p class="detail" v-html="detail"></p>
          <div class="target">
            <p>{{ target }}</p>
          </div>
        </div>

      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "ui-box-voice",
  components: {},
  props: {
    title: String,
    subTitle: String,
    detail: String,
    target: String,
  },
  data() {
    return {
      openVoice: false,
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.ui-box-voice {
  // width: 49%;
  background-color: white;
  margin-bottom: 30px;

  .box-voice-cover {
    padding: 25px;
    padding-bottom: 0;

    // position: relative;
    .title {
      display: flex;
      justify-content: center;
      border-bottom: 2px solid var(--green);
      padding-bottom: 10px;
      font-size: 22px;
      line-height: 1.2em;
      height: 80px;
      align-items: center;

      @media screen and (max-width: 768px) {
        font-size: 18px;
      }

      img {
        object-fit: cover;
        height: 50px;
        margin-right: 15px;
      }

      p {
        color: var(--green);
        font-weight: 700;
      }
    }

    .sub-title {
      font-weight: 700;
      line-height: 1.3em;
      padding: 20px 0;
      font-size: 22px;

      @media screen and (min-width: 767px) and (max-width: 1100px) {
        min-height: 140px;
      }

      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }

    .button {
      font-weight: 700;
      background-color: #ddd3c7;
      text-align: right;
      padding-right: 15px;
      color: white;
      height: 40px;

      span {
        font-size: 17px;
        vertical-align: bottom;
        // padding: 0;
        // margin: 0;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    .detail-cover {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 350px;
      padding-bottom: 25px;

      // position: absolute;
      // background-color: white;
      // z-index: 2;
      // opacity: 0.9;
      // padding: 20px 0;
      // background-color: white;
      .detail {
        line-height: 1.8em;
        font-size: 18px;
        padding: 20px 0;
        flex-basis: 80%;

        @media screen and (max-width: 768px) {
          font-size: 16px;
        }
      }

      .target {
        flex-basis: 10%;
        text-align: right !important;
      }
    }

    .back {
      // background-color: #fffef7;
      display: none;
      // margin-right: -20px;
      // margin-left: -20px;
    }
  }
}

.v-enter {
  opacity: 0;
}

.v-enter-active {
  transition: opacity 1s;
}

.v-enter-to {
  opacity: 1;
}

.v-leave {
  opacity: 2;
}

.v-leave-active {
  transition: opacity 0.5s;
}

.v-leave-to {
  opacity: 0;
}
</style>
