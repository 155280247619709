<template>
  <div class="ctrl-select-category-category-selector">
    <h1>{{ pageName }}のカテゴリ選択</h1>

    <div class="cover">
      <p>{{ pageName }}のカテゴリを選択してください。</p>
      <el-select v-model="categoryId" placeholder="選択してください">
        <el-option v-for="(categoryData, index) in categoryList" :key="index" :label="categoryData.name"
          :value="categoryData.id">
        </el-option>
      </el-select>
      <div class="button-cover">
        <el-button @click="back" type="info" class="back">戻る</el-button>
        <el-button type="primary" @click="selected">決定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "category-selector",
  components: {},
  props: {
    pageCode: String,
  },
  data() {
    return {
      categoryId: "",
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    categoryList() {
      // 
      let categoryList = this.$store.getters.categoryList;
      if (this.pageCode === 'columns') {
        categoryList = this.$store.getters.allCategoryList;
      } else {
        // no action.
      }

      // 
      return categoryList;
    },
    pageName() {
      // 
      let pageName = 'undefined';
      switch (this.pageCode) {
        case 'columns':
          pageName = '買取コラム';
          break;

        default:
          // 
          break;
      }

      // 
      return pageName;
    },
  },
  methods: {
    selected() {
      if (this.categoryId !== "") {
        this.$router.push(`/ctrl/${this.pageCode}/${this.categoryId}`);
      } else {
        alert("選択してください。");
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 28px !important;
}

.cover {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 100px;
  background-color: #f7f7f7;

  @media screen and (max-width: 768px) {
    padding: 50px 10px;
  }
}

.el-input {
  width: 90%;
  padding-bottom: 50px;

  @media screen and (max-width: 767px) {
    width: 95%;
    font-size: 17px;
  }
}

h1 {
  text-align: center;
  padding: 30px 0;
  font-size: 22px;
}

.el-input__inner {
  padding-right: 100px;

  @media screen and (max-width: 767px) {
    padding-right: 0;
  }
}

.el-select {
  width: 70%;
  margin-top: 30px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
</style>
