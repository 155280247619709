<template>
  <div class="home-if-you-buy">
    <div class="cover">
      <div class="title">
        <img :src="`${rootUrl}/img/logo2.png`" class="pc-only" alt="reallygood-ロゴ" />
        <h1>
          レコード・オープンリール・ヴィンテージオーディオの買取ならリアリーグッドへご相談ください
        </h1>
      </div>
      <p>
        {{ cityText
        }}に店舗を構えるレコード・オーディオ買取・販売専門店リアリーグッドでは、クラシックやジャズのレコード、オーディオ機器やオープンリールを強化買取中です。高価買取実績が多数あります。専門的だからこそ実現できる適切な査定で、納得の買取金額をご提示させていただきます。宅配、出張、店頭持ち込みでの買取が可能となっております。是非お気軽にご相談くださいませ。
      </p>
    </div>

    <div v-if="shopId !== null" style="margin-top: 50px;">
      <ShopData :shopId="shopId" />
    </div>

    <div v-if="shopId !== null" style="display: flex;justify-content: center;margin-top: 70px;">
      <img :src="`${rootUrl}/img/midashi.jpg`" style="width: 600px;" alt="">
    </div>

    <div class="buy-back-list">
      <div class="buy-back" v-for="(categoryData, index) in categoryList" :key="index">
        <img :src="`${rootUrl}/img/top/${categoryData.code}-thumbnail.jpg`"
          @click="$router.push(`/${categoryData.code}`)" :alt="categoryData.name" />
        <p>{{ categoryData.name }}</p>
      </div>
    </div>
    <!-- <div class="buy-back-list">
      <div class="buy-back" v-for="item in items" :key="item.id">
        <img :src="`${rootUrl}/img/${item.imgpath}`" :alt="item.name" />
        <p>{{ item.name }}</p>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShopData from "@/components/Shop/ShopData.vue";
import ShopConsts from "@/consts/ShopConsts";

export default {
  name: "home-if-you-buy",
  components: {
    ShopData
  },
  props: {
    shopId: [null, String]
  },
  data() {
    return {
      // Consts
      ShopConsts: ShopConsts,

      // 
      items: [
        { id: 1, imgpath: "buy-back01.jpg", name: "クラシックレコード" },
        { id: 2, imgpath: "buy-back02.jpg", name: "ジャズレコード" },
        { id: 3, imgpath: "buy-back03.jpg", name: "洋楽・邦楽レコード" },
        { id: 4, imgpath: "buy-back04.jpg", name: "SPレコード" },
        { id: 5, imgpath: "buy-back05.jpg", name: "音楽書籍" },
        { id: 6, imgpath: "buy-back06.jpg", name: "CD／DVD" },
        { id: 7, imgpath: "buy-back07.jpg", name: "オープンリールテープ" },
        { id: 8, imgpath: "buy-back08.jpg", name: "カセットテープ" },
        { id: 9, imgpath: "buy-back09.jpg", name: "DATテープ" },
        { id: 10, imgpath: "buy-back10.jpg", name: "レコードプレーヤー" },
        { id: 11, imgpath: "buy-back11.jpg", name: "レコード針／カートリッジ" },
        { id: 12, imgpath: "buy-back12.jpg", name: "アンプ" },
        { id: 13, imgpath: "buy-back13.jpg", name: "スピーカー" },
        { id: 14, imgpath: "buy-back14.jpg", name: "オープンリールデッキ" },
        { id: 15, imgpath: "buy-back15.jpg", name: "真空管／パーツ" },
      ],
    };
  },
  computed: {
    ...mapGetters(['rootUrl', 'categoryList']),
    cityText() {
      // 
      let text = "名古屋市、岡崎市";

      // 
      switch (this.shopId) {
        // 
        case ShopConsts.ID_NAGOYA:
          text = "名古屋市";
          break;

        // 
        case ShopConsts.ID_OKAZAKI:
          text = "岡崎市";
          break;

        default:
          // 
          break;
      }

      // 
      return text;
    }
  },
};
</script>

<style lang="scss" scoped>
.home-if-you-buy {
  .cover {

    @media screen and (max-width: 768px) {
      margin-top: 50px;
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 30px;
      line-height: 2.3em;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        line-height: 1.5em;
      }

      img {
        width: 8%;

        @media screen and (max-width: 768px) {
          width: 15%;
          margin-bottom: 10px;
        }
      }

      h1 {
        width: 90%;
        font-size: 35px;
        font-weight: 700;
        text-align: left;

        @media screen and (max-width: 768px) {
          font-size: 19px;
          width: 100%;
        }
      }
    }
  }

  .buy-back-list {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .buy-back {
      width: 31%;
      margin-bottom: 50px;

      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        margin-bottom: 25px;
      }

      p {
        text-align: center;
        font-weight: 700;

        @media screen and (max-width: 768px) {
          font-size: 15px;
        }
      }

      img {
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }

        @media screen and (max-width: 768px) {
          width: 80%;
        }
      }
    }
  }
}
</style>