<template>
  <div class="category-article-contents">
    <!--  -->
    <BackImageTitle :categoryId="articleData.categoryId" :title="articleData.title"
      :subTitle="`${categoryData.name}の高価買取リスト`" />
    <div class="article-text wrap960 space-100">
      {{ articleData.text }}
    </div>
    <!--  -->

    <!--  -->
    <div class="space-150 wrap960">
      <div class="record-box">
        <ArticleItemBox v-for="productId in articleData.productIdList" :key="productId"
          :categoryId="articleData.categoryId" :productId="productId" class="item-box" />
      </div>
    </div>
    <!--  -->

    <!--  -->
    <div class="fixed-back space-150" :style="`background-image: url(${rootUrl}/img/back-img02.jpg);`">
      <Reason :categoryId="articleData.categoryId" />
    </div>
    <!--  -->

    <!--  -->
    <div class="space-100 wrap960">
      <Promise :pageType="'article'" />
    </div>
    <!--  -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { setTitle } from "@/mixins/index.js";
import CategoryConsts from "@/consts/CategoryConsts";
import ArticleItemBox from "@/components/Article/ItemBox.vue";
import Reason from "@/components/Category/CategoryContents/Reason.vue";
import BackImageTitle from "@/components/Ui/BackImageTitle.vue";
import Promise from "@/components/Ui/Promise.vue";

export default {
  name: "article-contents",
  components: {
    ArticleItemBox,
    Reason,
    Promise,
    BackImageTitle,
  },
  props: {
    articleId: String,
  },
  data() {
    return {
      // Consts
      CategoryConsts: CategoryConsts,

      // 
      topImageFileName: "",
      articleData: {
        id: "",
        categoryId: "",
        title: "",
        text: "",
        productIdList: [],
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    categoryData() {
      return this.$store.getters.categoryData(this.articleData.categoryId);
    }
  },
  watch: {},
  created() {
    this.$axios
      .get(
        `${this.rootUrl}/api/article/get-article-data?article_id=${this.articleId}`
      )
      .then((response) => {
        console.log(response);
        if (response.data.status === 200) {
          this.articleData = response.data.articleData;

          // 
          setTitle(`${response.data.articleData.title}の高価買取｜リアリーグッド`);

          // 
          return;
        } else if (
          response.data.status === 401 ||
          response.data.status === 400
        ) {
          alert(response.data.message);
          return;
        } else {
          alert("エラーが発生しました。");
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.design {
  width: 100vw !important;
  vertical-align: top;
}

.spcae1 {
  padding-bottom: 250px;
}

.record-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {}

  // justify-content: space-between;
  .item-box {
    width: 24%;
    margin-right: 1%;

    @media screen and (max-width: 768px) {
      width: 48%;
      margin-right: 0;
    }
  }
}

.record-box::before {
  content: "";
  display: block;
  width: 24%;
  order: 1;

  @media screen and (max-width: 768px) {
    width: 48%;
  }
}

.record-box::after {
  content: "";
  display: block;
  width: 24%;

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.fixed-back {
  position: relative;
  // min-height: 100%;
  // height: 1350px; /*--スクロールをさせるため 高さ指定--*/
  background-size: cover;
  /*--背景画像のサイズ--*/
  // background-attachment: fixed; /*--背景画像の固定--*/
  background-repeat: no-repeat;
  /*--背景画像の繰り返し--*/
  background-position: center center;
  /*--背景画像の位置--*/
  z-index: 2;
  padding: 5em 0;

  @media screen and (max-width: 1024px) {
    background-attachment: initial;
    width: 100%;
    // background-size: auto 100%;
    // background-size:contain;
  }
}
</style>
