<template>
  <div class="ui-assessment-points">
    <div class="title-cover">
      <img :src="`${rootUrl}/img/category/assessment-points/${categoryData.code}-title.png`"
        :alt="`${categoryData.name}の査定ポイント`" />
    </div>
    <UiBoxPoint :imgpath="point01Data.imagePath" :name="point01Data.imageAlt" :title="point01Data.title"
      :des="point01Data.text" />
    <UiBoxPoint :imgpath="point02Data.imagePath" :name="point02Data.imageAlt" :title="point02Data.title"
      :des="point02Data.text" />
    <UiBoxPoint :imgpath="point03Data.imagePath" :name="point03Data.imageAlt" :title="point03Data.title"
      :des="point03Data.text" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBoxPoint from "@/components/Ui/BoxPoint";
import CategoryConsts from "@/consts/CategoryConsts";

export default {
  name: "classicrecord-assessment-points",
  components: {
    UiBoxPoint,
  },
  props: {
    categoryId: String,
  },
  data() {
    return {
      // Consts
      CategoryConsts: CategoryConsts,

      // 
      point01Data: {
        imagePath: 'classic-point01.png',
        imageAlt: '輸入盤イメージ',
        title: '国内盤より輸入盤',
        text: '国内盤は市場の流通量の多さから、高額になる人気タイトルが限定されます。人気のタイトルは、日本独自の企画盤、高音質盤などになります。それに比べ輸入盤、特にオリジナル盤などは稀少性、音質面を含め、現在でも安定した需要があり高額買取の期待が持てます。',
      },
      point02Data: {
        imagePath: 'classic-point02.png',
        imageAlt: '付属品イメージ',
        title: '付属品も確認',
        text: 'レコードはジャケット、レコード盤の状態とは別に、付録や付属品なども重要な買取要素です。特に国内盤は、基本的に元々、帯・解説書といった付属品がついています。この付属品が有るか無いか、また状態も買取価格に大きく影響します。比較的流通しているものでも、付属品がすべて揃っている綺麗な状態なら高額になる作品も多数です。',
      },
      point03Data: {
        imagePath: 'classic-point03.png',
        imageAlt: 'チェックイメージ',
        title: `ご依頼前に<br class='sp-only'>状態チェック`,
        text: '状態はレコード査定に大きく左右します。リアリーグッドではジャケットの汚れや盤の傷など細かく6段階で評価します。カバーやケースに収納され丁寧に保管されているものは、満額査定の期待が持てます。埃が被った状態でも依頼前にジャケットを軽く拭いて掃除しておくと、査定時の印象が上がります。',
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    categoryData() {
      return this.$store.getters.categoryData(this.categoryId);
    },
  },
  created() {
    switch (this.categoryId) {
      // 
      case CategoryConsts.ID_OPEN_REEL:
        // 
        this.point01Data.imagePath = 'openreel-point01.png';
        this.point01Data.imageAlt = '輸入盤イメージ';
        this.point01Data.title = 'オープンリールデッキは電源を確認';
        this.point01Data.text = '電源が入り通電していればOKです。動作不良でも、買取可能です。ダストカバーや付属品などが残っていると査定時に買取アップが期待できます。メーカーと型式をお問い合わせください。';
        // 
        this.point02Data.imagePath = 'openreel-point02.png';
        this.point02Data.imageAlt = 'ミュージックテープイメージ';
        this.point02Data.title = 'ミュージックテープはジャンルを確認';
        this.point02Data.text = 'ジャズやロックのテープは人気が高く、特に日本製のテープは稀少度が更に増します。また2トラック38cmで録音された作品は高額買取出来ます。クラシックやその他のジャンルでも作品によって高価買取が期待できます。是非お問い合わせください。';
        // 
        this.point03Data.imagePath = 'record06.png';
        this.point03Data.imageAlt = 'オープンリールイメージ';
        this.point03Data.title = '録音用オープンリールテープ(生テープ)';
        this.point03Data.text = 'MAXELLやTEAC製は人気が高く、メタルリール (金属製のリール)は高価買取出来ます。 特に10号(30cm)の大きさが人気です。未使用品はもちろん、使用済み、箱がなくなったものでもコンディションが良ければ高価買取が期待できます。その他オープンリールテープはすべて買取可能です。お問い合わせお待ちしております。';
        break;
      // 
      case CategoryConsts.ID_AUDIO:
        this.point01Data.title = '壊れていても大丈夫';
        this.point01Data.text = '電源が入らない、再生しない、音が出ないなど、故障をしていても大丈夫です。修理対応や部品取りでの手段もあるため、壊れているから値段が付かないわけではありません。まずは状態を問わず、メーカーと型式をお問い合わせください。';
        this.point02Data.text = 'オーディオは説明書や元箱、付属品も重要な買取要素です。まとめて閉まってある場合は、忘れずに準備しましょう。付属品がすべて揃っていると査定時に買取アップが期待できます。';
        this.point03Data.title = '掃除はしなくても大丈夫です';
        this.point03Data.text = '古いオーディオ機器は精密な部品が多く、掃除による出し入れや接触によって壊れてしまうこともあります。埃を取る程度の簡単な清掃で十分です。出来るだけそのままの状態でご依頼ください。';
        break;
      // 
      case CategoryConsts.ID_AUDIO_CD:
        this.point01Data.title = '音質に特化した仕様';
        this.point01Data.text = '専用プレーヤーで再生するSACDや、反射膜に24Kの金を使用したゴールドCD、全工程を徹底的に音質管理して作成されたXRCDなど、通常のCDと異なった仕様のCDは稀少性、音質面を含め、現在でも安定した需要があり高額買取の期待が持てます。';
        this.point02Data.text = 'CDはケース、ディスクの状態とは別に、付録や付属品なども重要な買取要素です。特に国内盤は、基本的に元々、帯・日本語解説書といった付属品がついています。この付属品が有るか無いか、また状態も買取価格に大きく影響します。比較的流通しているものでも、付属品がすべて揃っている綺麗な状態なら高額になる作品も多数です。';
        this.point03Data.text = '状態はCD査定に大きく左右します。リアリーグッドではケースの汚れやディスクの傷など細かく4段階で評価します。カバーやケースに収納され丁寧に保管されているものは、満額査定の期待が持てます。埃が被った状態でも依頼前にケースを軽く拭いて掃除しておくと、査定時の印象が上がります。';
        break;
      // 
      case CategoryConsts.ID_AUDIO_PARTS:
        this.point01Data.title = '未使用品';
        this.point01Data.text = '未使用のものは、現在では製造されていないものもあり、大小問わず需要があります。<br>特にヴィンテージのもので開封されていないものなどは高額買取の期待が持てます。専門性や希少性を理解できるリアリーグッドでの査定がおすすめです。';
        this.point02Data.text = '真空管/トランス/オーディオ・アクセサリーなどの小物でも説明書や元箱、付属品も重要な買取要素です。付属品がすべて揃っていると査定時に買取アップが期待できます。';
        this.point03Data.text = '状態は査定に大きく左右します。リアリーグッドでは汚れや傷、使用感など細かく4段階で評価します。使用済みでも、カバーやケースに収納され丁寧に保管されているものは、満額査定の期待が持てます。埃が被った状態でも依頼前に軽く拭いて掃除しておくと、査定時の印象が上がります。';
        break;
      // 
      case CategoryConsts.ID_BOOKS:
        this.point01Data.title = '専門書やディスクガイド';
        this.point01Data.text = '音楽のジャンルに特化した書物や、オーディオの専門書は発行部数も少なく、現在でも安定した需要があり高額買取の期待が持てます。専門性や希少性を理解できるリアリーグッドでの査定がおすすめです。';
        this.point02Data.text = '書籍は本の状態とは別に、帯や付録など付属品も重要な買取要素です。外してしまってある場合などは、つけてご依頼することをお薦め。付属品がすべて揃っている綺麗な状態なら高額になる作品も多数です。';
        this.point03Data.text = '状態は書籍査定に大きく左右します。リアリーグッドではカバーから内部のページを精査し、細かく4段階で評価します。カバーやケースに収納され丁寧に保管されているものは、満額査定の期待が持てます。埃が被った状態でも依頼前に軽く拭いて掃除しておくと、査定時の印象が上がります。';
        break;
      // 
      case CategoryConsts.ID_OTHERS:
        this.point01Data.title = '未使用、未開封品';
        this.point01Data.text = '開封のされていない未使用のメディアは、元々安価で販売されていたものでも需要があります。定価の数倍の査定額がつくものも多く、カセットテープなどのテープ類は高額になる傾向です。';
        this.point02Data.title = '録音済み、使用済みのテープ';
        this.point02Data.text = 'CDなどの音源を録音したカセットテープやMDテープ、DATテープなども再録音が可能なため、一定の需要があります。各メディア、メーカーや型式によって人気のタイプがあり、使用済みでも高額買取の可能性があります。';
        this.point03Data.text = '状態は査定に大きく左右します。リアリーグッドではケースの汚れや本体の使用感など細かく4段階で評価します。使用済みのテープでも、カバーやケースに収納され丁寧に保管されているものは、満額査定の期待が持てます。埃が被った状態でも依頼前にケースを軽く拭いて掃除しておくと、査定時の印象が上がります。';
        break;

      default:
        // no action.
        break;
    }
  }
};
</script>

<style lang="scss" scoped>
.title-cover {
  padding-top: 200px;

  @media screen and (max-width: 768px) {
    padding-top: 100px;
  }
}
</style>