<template>
  <div class="home-achievement">
    <div class="wrap960">
      <div class="title-cover">
        <img :src="`${rootUrl}/img/teiel01.png`" alt="専門店だから、この買い取り実績" />
        <div class="sub-title">
          <img :src="`${rootUrl}/img/record01.png`" alt="レコードのイラスト1" class="record-1" />
          <p>買取実績一覧</p>
          <img :src="`${rootUrl}/img/record02.png`" alt="レコードのイラスト2" class="record-2" />
        </div>
      </div>
    </div>
    <div class="slider-cover">
      <UiSliderBox2 :type="'default'" :boxes="topResultList" :swiperOption="swiperOption" :height="100" :width="98"
        :titleType="'categoryName'" :button="false" class="slider" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiSliderBox2 from "../Ui/SliderBox2.vue";

export default {
  name: "home-achievement",
  components: {
    UiSliderBox2,
  },
  props: {},
  data() {
    return {
      boxes: [
        {
          id: 1,
          imgpath: "graybox-01.jpg",
          name: "sample1",
          title: "ジャズレコード",
          description:
            "祖父が集めていた<br>ヴィンテージの<br>ジャズレコード􏚹􏚱枚",
          price: "10,000",
        },
        {
          id: 2,
          imgpath: "graybox-01.jpg",
          name: "sample1",
          title: "オープンリール",
          description:
            "祖父が集めていた<br>ヴィンテージの<br>ジャズレコード􏚹􏚱枚",
          price: "10,000",
        },
        {
          id: 3,
          imgpath: "graybox-01.jpg",
          name: "sample1",
          title: "ヴィンテージオーディオ",
          description:
            "祖父が集めていた<br>ヴィンテージの<br>ジャズレコード􏚹􏚱枚",
          price: "10,000",
        },
        {
          id: 4,
          imgpath: "graybox-01.jpg",
          name: "sample1",
          title: "クラシックレコード",
          description:
            "祖父が集めていた<br>ヴィンテージの<br>ジャズレコード􏚹􏚱枚",
          price: "10,000",
        },
        {
          id: 5,
          imgpath: "graybox-01.jpg",
          name: "sample1",
          title: "ヴィンテージオーディオ",
          description:
            "祖父が集めていた<br>ヴィンテージの<br>ジャズレコード􏚹􏚱枚",
          price: "10,000",
        },
      ],
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 50,
        speed: 500,
        effect: "slide",
        loop: true,
        centeredSlides: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        //ナビゲーション設定
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1200: {
          slidesPerView: 2,
        },
        600: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "topResultList"]),
  },
};
</script>

<style lang="scss" scoped>
.home-achievement {
  .title-cover {
    @media screen and (max-width: 768px) {
      padding-top: 50px;
    }
  }

  .sub-title {
    margin: 30px auto;
    display: flex;
    align-items: center;
    width: 430px;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .record-1 {
      width: 100px;

      @media screen and (max-width: 768px) {
        width: 20%;
      }
    }

    .record-2 {
      width: 50px;
      margin-right: 50px;

      @media screen and (max-width: 768px) {
        width: 10%;
        margin-left: 13px;
        margin-right: 0;
        text-align: center;
      }
    }

    p {
      font-size: 28px;
      font-weight: 700;
    }
  }
}
</style>