<template>
  <div class="category-category-contents-reason">
    <div class="wrap1100">
      <div class="title-cover wrap960">
        <img :src="`${rootUrl}/img/title18.png`" alt="買取についてよくある質問" />
      </div>
      <UiBoxReason :imgpath="reason01Data.imagePath" :name="reason01Data.imageAlt"
        :title="reason01Data.title" :des="reason01Data.text" />
      <UiBoxReason :imgpath="reason02Data.imagePath" :name="reason02Data.imageAlt" :title="reason02Data.title"
        :des="reason02Data.text" />
      <UiBoxReason :imgpath="reason03Data.imagePath" :name="reason03Data.imageAlt" :title="reason03Data.title"
        :des="reason03Data.text" />
      <UiBoxReason :imgpath="reason04Data.imagePath" :name="reason04Data.imageAlt" :title="reason04Data.title"
        :des="reason04Data.text" />
      <UiBoxReason :imgpath="reason05Data.imagePath" :name="reason05Data.imageAlt" :title="reason05Data.title"
        :des="reason05Data.text" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBoxReason from "@/components/Ui/BoxReason";
import CategoryConsts from "@/consts/CategoryConsts";

export default {
  name: "reason",
  components: {
    UiBoxReason,
  },
  props: {
    categoryId: String,
  },
  data() {
    return {
      // Consts
      CategoryConsts: CategoryConsts,

      // 
      reason01Data: {
        imagePath: ``,
        imageAlt: ``,
        title: ``,
        text: ``,
      },
      reason02Data: {
        imagePath: ``,
        imageAlt: ``,
        title: ``,
        text: ``,
      },
      reason03Data: {
        imagePath: ``,
        imageAlt: ``,
        title: ``,
        text: ``,
      },
      reason04Data: {
        imagePath: ``,
        imageAlt: ``,
        title: ``,
        text: ``,
      },
      reason05Data: {
        imagePath: ``,
        imageAlt: ``,
        title: ``,
        text: ``,
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    // categoryData() {
    //   return this.$store.getters.categoryData(this.categoryId);
    // },
  },
  watch: {
    categoryId: {
      handler() {
        // 
        const categoryData = this.$store.getters.categoryData(this.categoryId);

        // 
        const reason01Data = {
          imagePath: `top/${categoryData.code}-thumbnail.jpg`,
          // imagePath: `reason-01.jpg`,
          imageAlt: `レコードイメージ`,
          title: `レコード専門店だから実現できる圧倒的な買取力`,
          text: `レコード専門店だからこそ、レコードに詳しいスタッフが適切な査定を行うことができます。<br>リサイクルシ ョップではできない専門的な査定ができるため、他にはない高価買取を実現しています。<br>段ボール１箱でいくらなどざっくり査定をせず、１つ１つを丁寧に査定。<br>必要であれば再生機器でのチェックを行うなど、状態を細部まで把握して査定金額を算出します。<br>付属品も買取表を元に価値のあるものを見逃しません。`,
        };
        const reason02Data = {
          imagePath: `reason-02.jpg`,
          imageAlt: `店内イメージ`,
          title: `買取率99%！${categoryData.name}なら何でも買い取れる`,
          text: `リアリーグッドは、${categoryData.name}に特化したレコード店です。そのため、${categoryData.name}について買い取れないものはほぼありません。買取率は99%の実績です。迷ったらリアリーグッドへどうぞ。`,
        };
        const reason03Data = {
          imagePath: `reason-03.jpg`,
          imageAlt: `買取体制イメージ`,
          title: `お客様が利用しやすい<br>買取体制`,
          text: `宅配買取の送料、出張買取の出張料金、査定料、お支払い時の振込手数料、返送料・引取料などすべて無料で、お客様の負担は一切ございません。ご希望のお客様には梱包キットのご用意もしております。お客様が損をする、失敗することがないため、お気軽に安心してご利用いただけます。`,
        };
        const reason04Data = {
          imagePath: `reason-04.jpg`,
          imageAlt: `査定イメージ`,
          title: `スピーディーな査定！<br>１営業日以内に対応`,
          text: `お客様をお待たせしないよう、１営業日以内でのお見積もり回答を行っています。<br>お急ぎのお客様の査定も迅速かつ丁寧に査定させていただきます。<br>スケジュールが合えば当日出張も可能です。引っ越しや遺品整理など早急なご依頼にも対応いたします。`,
        };
        const reason05Data = {
          imagePath: `reason-05.jpg`,
          imageAlt: `お客様へイメージ`,
          title: `しっかり次のお客さまへ<br>繋げます`,
          text: `リアリーグッドではお客さまより買取させて頂いたお品物を、そのまま販売することはございません。レコードは専用のクリーニング機械で一枚一枚丁寧に掃除をし、新品の袋に変えて、大切に販売します。テープは1本1本録音データに欠損や不良がないか、再生チェックをした後に販売します。オーディオ機器は入念なチェックをして、正常、不良箇所を把握した上で、必要なら修理・メンテナンスを施して販売します。レコード を愛しているお客様との取引が多数ある専門店だからこそ、次のお客さまにしっかりと繋げることができます。`,
        };

        // 
        switch (this.categoryId) {
          // 
          case CategoryConsts.ID_OPEN_REEL:
            reason01Data.title = `${categoryData.name}の買取ができる日本では数少ない店舗`;
            reason01Data.text = `リアリーグッドは、日本国内でも珍しい、${categoryData.name}に関連するアイテムの買取を行っているレコード専門店です。リサイクルショップではできない専門的な査定ができるからこそ、他にはない高価買取を実現しています。${categoryData.name}を売りたいならリアリーグッドをぜひご利用ください。`;
            break;
          // 
          case CategoryConsts.ID_AUDIO_CD:
            reason01Data.title = `音質にも拘りをもった専門店だから実現できる圧倒的な買取力`;
            reason01Data.text = `音質に拘る専門店だからこそ、専門的なCDに詳しいスタッフが適切な査定を行うことができます。音質に特化したCDはもちろん、通常のCDもしっかりと査定します。<br>リサイクルシ ョップではできない専門的な査定ができるため、他にはない高価買取を実現しています。<br>段ボール１箱でいくらなどざっくり査定をせず、１つ１つを丁寧に査定。<br>必要であれば再生機器でのチェックを行うなど、状態を細部まで把握して査定金額を算出します。<br>付属品も買取表を元に価値のあるものを見逃しません。`;
            reason02Data.title = `買取率99%！CDなら何でも買い取れる`;
            reason02Data.text = `リアリーグッドは、CDに特化したレコード店です。そのため、オールジャンル買い取れないものはほぼありません。買取率は99%の実績です。迷ったらリアリーグッドへどうぞ。`;
            break;
          // 
          case CategoryConsts.ID_AUDIO:
            reason01Data.title = `オーディオに拘りをもったレコード専門店だから実現できる圧倒的な買取力`;
            reason01Data.text = `オーディオに特化した専門店だからこそ、オーディオに詳しいスタッフが適切な査定を行うことができます。<br>リサイクルショップではできない専門的な査定ができるからこそ、他にはない高価買取を実現しています。オーディオを売りたいならリアリーグッドをぜひご利用ください。`;
            reason02Data.title = `買取率99%！オーディオなら何でも買い取れる`;
            reason02Data.text = `リアリーグッドは、オーディオに特化したレコード店です。そのため、オーディオについて買い取れないものはほぼありません。買取率は99%の実績です。迷ったらリアリーグッドへどうぞ。`;
            break;
          // 
          case CategoryConsts.ID_AUDIO_PARTS:
            reason01Data.title = `オーディオに精通したレコード専門店だから、周辺アクセサリー・パーツにも圧倒的な買取力`;
            reason01Data.text = `オーディオに特化した専門店だからこそ、周辺アクセサリー・パーツに詳しいスタッフが適切な査定を行うことができます。<br>リサイクルショップではできない専門的な査定ができるからこそ、他にはない高価買取を実現しています。オーディオの周辺アクセサリー・パーツを売りたいならリアリーグッドをぜひご利用ください。`;
            reason02Data.title = `買取率99%！真空管/トランス/オーディオ・アクセサリーなら何でも買い取れる`;
            reason02Data.text = `リアリーグッドは、真空管/トランス/オーディオ・アクセサリーに特化したレコード店です。そのため、音楽メディアで買い取れないものはほぼありません。買取率は99%の実績です。迷ったらリアリーグッドへどうぞ。`;
            break;
          // 
          case CategoryConsts.ID_BOOKS:
            reason01Data.title = `音楽に拘りをもった専門店だから実現できる圧倒的な買取力`;
            reason01Data.text = `音楽に拘る専門店だからこそ、専門的な書籍に詳しいスタッフが適切な査定を行うことができます。音楽専門書はもちろん、雑誌やオーディオなど関連書籍もしっかりと査定します。<br>リサイクルシ ョップではできない専門的な査定ができるため、他にはない高価買取を実現しています。<br>段ボール１箱でいくらなどざっくり査定をせず、１つ１つを丁寧に査定。<br>必要であれば再生機器でのチェックを行うなど、状態を細部まで把握して査定金額を算出します。<br>付属品も買取表を元に価値のあるものを見逃しません。`;
            reason02Data.title = `買取率99%！CDなら何でも買い取れる`;
            reason02Data.text = `リアリーグッドは、音楽に特化したレコード店です。そのため、音楽に関連する書物なら買い取れないものはほぼありません。買取率は99%の実績です。迷ったらリアリーグッドへどうぞ。`;
            break;
          // 
          case CategoryConsts.ID_OTHERS:
            reason01Data.title = `カセットテープ/その他メディアにも拘りをもったレコード専門店だから実現できる圧倒的な買取力`;
            reason01Data.text = `カセットテープ/その他メディアに拘るレコード専門店だからこそ、専門的な音楽メディアに詳しいスタッフが適切な査定を行うことができます。カセットテープやオープンリールテープはもちろん、その他あらゆる音楽メディアもしっかりと査定します。<br>リサイクルショップではできない専門的な査定ができるため、他にはない高価買取を実現しています。<br>段ボール１箱でいくらなどざっくり査定をせず、１つ１つを丁寧に査定。<br>状態を細部まで把握して査定金額を算出します。`;
            reason02Data.title = `買取率99%！カセットテープ/音楽メディアなら何でも買い取れる`;
            reason02Data.text = `リアリーグッドは、カセットテープ/音楽メディアに特化したレコード店です。そのため、音楽メディアで買い取れないものはほぼありません。買取率は99%の実績です。迷ったらリアリーグッドへどうぞ。`;
            break;

          default:
            // no action.
            break;
        }

        // 
        this.reason01Data = reason01Data;
        this.reason02Data = reason02Data;
        this.reason03Data = reason03Data;
        this.reason04Data = reason04Data;
        this.reason05Data = reason05Data;
      },
      immediate: true,
    },
  },
  created() {
  }
};
</script>

<style lang="scss" scoped>
.title-cover {
  width: 100%;
}
</style>