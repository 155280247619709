<template>
  <div class="category-contents">

    <!--  -->
    <div class="top">
      <img :src="`${rootUrl}/img/category/${categoryData.code}-top.jpg`" class="pc" alt="really-goodトップ" />
      <img :src="`${rootUrl}/img/category/${categoryData.code}-top-sp.jpg`" class="sp" alt="really-goodトップ" />
    </div>
    <!--  -->

    <!--  -->
    <div class="back">
      <HomeGallery />
    </div>
    <!--  -->

    <!--  -->
    <div class="wrap960">
      <p class="top-text">
        <span v-if="
          categoryId === CategoryConsts.ID_CLASSIC_RECORD ||
          categoryId === CategoryConsts.ID_JAZZ_RECORD ||
          categoryId === CategoryConsts.ID_WJ_MUSIC_RECORD ||
          categoryId === CategoryConsts.ID_AUDIO_CD ||
          categoryId === CategoryConsts.ID_AUDIO ||
          categoryId === CategoryConsts.ID_BOOKS ||
          categoryId === CategoryConsts.ID_AUDIO_PARTS ||
          categoryId === CategoryConsts.ID_OTHERS
        ">
          {{ categoryData.name }}の買取なら、レコード専門店であり、かつ、{{ categoryData.name
          }}に特化したリアリーグッドをご利用ください。専門店だからこそできる適切な査定で高価買取を実現しています。お気軽にまずは査定からご依頼くださいませ。
        </span>
        <span
          v-else-if="categoryId === CategoryConsts.ID_OPEN_REEL">オープンリールデッキ・テープの買取なら、リアリーグッドをご利用ください。専門店だからこそできる適切な査定で高価買取を実現しています。お気軽にまずは査定からご依頼くださいませ。</span>
      </p>
    </div>
    <!--  -->

    <!--  -->
    <img alt="デザイントップ" :src="`${rootUrl}/img/reallygood-top3.png`" class="design" />
    <div style="background-color: #fff9e9" class="spcae3">
      <HomeAchievement />
    </div>
    <img alt="デザインボトム" :src="`${rootUrl}/img/reallygood-bottom3.png`" class="design" />
    <!--  -->

    <!--  -->
    <div class="space-50 high-value-buyback-records">
      <HighValueBuybackRecords :categoryId="categoryId" />
    </div>
    <!--  -->

    <!--  -->
    <div class="space-50 high-value-buyback-list">
      <StrongItem :categoryId="categoryId" />
    </div>
    <!--  -->

    <!--  -->
    <div class="fixed-back space-150" :style="`background-image: url(${rootUrl}/img/back-img02.jpg);`">
      <Reason :categoryId="categoryId" />
    </div>
    <!--  -->

    <!--  -->
    <div style="background-color: #fffef7" class="sapce-150">
      <div class="wrap960">
        <UiAssessmentPoints :categoryId="categoryId" />
      </div>
    </div>
    <!--  -->

    <!--  -->
    <img :src="`${rootUrl}/img/reallygood-top3.png`" class="design margin-top design3" alt="デザイントップ" />
    <!--  -->

    <!--  -->
    <div style="background-color: #fff9e9" class="spcae3 buy">
      <div class="wrap960">
        <HomeHowToBuy :shopId="null" />
      </div>
    </div>
    <img :src="`${rootUrl}/img/reallygood-bottom3.png`" class="design margin-bottom design4" alt="デザインボトム" />
    <!--  -->

    <!--  -->
    <div class="space-50">
      <HomeAboutUs :displayShopData="true" />
    </div>
    <!--  -->

    <!--  -->
    <div style="background-color: #fffef7" class="sapce-150">
      <div class="wrap1100">
        <CustomersVoice :categoryId="categoryId" />
      </div>
    </div>
    <!--  -->

    <!--  -->
    <div class="wrap960 space-100">
      <HomeQa />
    </div>
    <!--  -->

    <!--  -->
    <div class="space-100 wrap960">
      <Promise :pageType="'category'" />
    </div>
    <!--  -->

    <!--  -->
    <div class="wrap960">
      <!-- <UiColumn :categoryId="categoryId" /> -->
      <ColumnList :categoryId="categoryId" />
    </div>
    <!--  -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CategoryConsts from "@/consts/CategoryConsts";
import HomeGallery from "@/components/Home/Gallery.vue";
import HomeAchievement from "@/components/Home/Achievement.vue";
import HighValueBuybackRecords from "@/components/Category/CategoryContents/HighValueBuybackRecords.vue";
import Reason from "@/components/Category/CategoryContents/Reason.vue";
import StrongItem from "@/components/Ui/StrongItem.vue";
import UiAssessmentPoints from "@/components/Ui/AssessmentPoints.vue";
import HomeHowToBuy from "@/components/Home/HowToBuy.vue";
import HomeAboutUs from "@/components/Home/AboutUs.vue";
import CustomersVoice from "@/components/Category/CategoryContents/CustomersVoice.vue";
import HomeQa from "@/components/Home/Qa.vue";
import Promise from "@/components/Ui/Promise.vue";
// import UiColumn from "@/components/Ui/Column.vue";
import ColumnList from "@/components/Ui/ColumnList.vue";

export default {
  name: "CategoryContents",
  components: {
    HomeGallery,
    HomeAchievement,
    HighValueBuybackRecords,
    Reason,
    StrongItem,
    UiAssessmentPoints,
    HomeHowToBuy,
    HomeAboutUs,
    CustomersVoice,
    Promise,
    HomeQa,
    // UiColumn,
    ColumnList
  },
  props: {
    categoryId: String
  },
  data() {
    return {
      // Consts
      CategoryConsts: CategoryConsts,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    categoryData() {
      return this.$store.getters.categoryData(this.categoryId);
    }
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.high-value-buyback-records {
  @media screen and (max-width: 768px) {
    margin-top: 60px;
  }
}

.high-value-buyback-list {
  margin-top: 150px;
}

.top {
  margin: 0 auto;
  max-width: 1100px;
  width: 100vw;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 20px 0;
  }

  .pc {
    width: 95%;
    margin-top: 70px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .sp {
    display: none;

    @media screen and (max-width: 768px) {
      display: initial;
      width: 100%;
    }
  }

}

.back {
  padding-top: 10px;
  padding-bottom: 50px;
  background-size: cover;
  background-repeat: no-repeat;
}

.top-text {
  font-size: 20px;
  margin-bottom: 100px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 30px;
  }
}

.design {
  width: 100vw !important;
  vertical-align: top;

  &.design3 {
    @media screen and (max-width: 768px) {
      margin-top: 10px;
    }
  }
  &.design4 {
    @media screen and (max-width: 768px) {
      margin-bottom: 50px;
    }
  }
}

.spcae1 {
  padding-bottom: 250px;
}

.spcae3 {
  padding-bottom: 50px;

  @media screen and (max-width: 768px) {
    padding-bottom: 20px;
  }
}

.fixed-bottom {
  padding-bottom: 50px;

  @media screen and (max-width: 768px) {
    padding-bottom: 20px;
  }
}

.margin-top {
  margin-top: 150px;
}

.margin-bottom {
  margin-bottom: 150px;
}

.strong {
  @media screen and (max-width: 768px) {
    padding-top: 50px;
  }
}

.buy {
  @media screen and (max-width: 768px) {
    padding-bottom: 30px;
  }
}
</style>
