import { render, staticRenderFns } from "./SliderBox2.vue?vue&type=template&id=562b4c9a&scoped=true&"
import script from "./SliderBox2.vue?vue&type=script&lang=js&"
export * from "./SliderBox2.vue?vue&type=script&lang=js&"
import style0 from "./SliderBox2.vue?vue&type=style&index=0&id=562b4c9a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "562b4c9a",
  null
  
)

export default component.exports