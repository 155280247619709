<template>
  <div class="columns">
    <ColumnList :text="text" :categoryId="categoryId"/>
  </div>
</template>

<script>
import ColumnList from "@/components/Columns/ColumnList.vue";

export default {
  name: "columns",
  components: {
    ColumnList,
  },
  props: {
    // 
  },
  data() {
    return {
      text: '',
      categoryId: null,
    };
  },
  computed: {},
  watch: {},
  created() {
    // 
    const query = this.$route.query;
    if (query.text !== undefined) {
      this.text = query.text;
    } else {
      // no action.
    }
    if (query.categoryId !== undefined) {
      this.categoryId = query.categoryId;
    } else {
      // no action.
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
