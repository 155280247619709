<template>
  <div class="home-how-to-buy-in-store">
    <div class="flow">
      <p class="title center">店頭買取の流れ</p>
      <table v-if="shopId === null" class="tab">
        <tr>
          <th v-for="shopData in shopList" @click="slectedShopId = shopData.id" class="tab-button"
            :class="{ active: slectedShopId === shopData.id }" :key="shopData.id">{{ shopData.name }}</th>
        </tr>
      </table>
      <!-- <div class="tab-button-cover">
        <div class="tab-button"></div>
        <div class="tab-button"></div>
      </div> -->
      <div class="flow-box">
        <div class="box center-c">
          <img :src="`${rootUrl}/img/in-store01.png`" alt="かごイラスト" class="truck" />
          <p>買い物のついでに<br />1点からお持ち込みOK</p>
        </div>
        <div class="box center-c">
          <img :src="`${rootUrl}/img/in-store02.png`" alt="お支払いイラスト" class="box" />
          <p>当日その場で<br />お支払い</p>
        </div>
        <div class="box center-c">
          <img :src="`${rootUrl}/img/in-store03.png`" alt="電車イラスト" class="train" />
          <p v-html="shopData.nearestStationText"></p>
        </div>
      </div>
    </div>

    <UiBox :type="`green`" :title="`お持ち込み`" :imgpath="shopData.interiorImageFileName" :name="``"
      :text="`査定は1点からOK！数点であればその場で査定いたします。<br>身分証(※運転免許証、健康保険証、パスポート、学生証、住民票の写しなど)をお持ちの上ご来店ください。<br>${shopData.placeText}で、お車でのアクセスも良好です。<br>お車での大量のお持ち込みの場合は、荷下ろしさせていただきます。`" />
    <UiBox :type="`green`" :title="`その場で査定`" :imgpath="`delivery08.jpg`" :name="`査定結果イメージ`" :text="`専門スタッフが丁寧かつスピーディーに査定させていただきます。
内容・量によってはお預かりする場合がございます。`" />

    <UiBox :type="`green`" :title="`即日お支払い`" :imgpath="`delivery09.jpg`" :name="`お支払いイメージ`"
      :text="`査定額のご了承を頂けましたら、その場でお支払いいたします。`" />

    <div class="access">
      <p class="title">アクセス ACCESS</p>

      <div class="access-cover">
        <div class="map">
          <iframe :src="shopData.googleMapSrc" width="100%" height="335px" style="border:0;" allowfullscreen=""
            loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="access-detail">
          <p>
            【Address】<br />
            <strong v-html="shopData.address"></strong>
          </p>
          <p>
            【Tel】<br />
            店舗：{{ shopData.phoneNumber }}<br />
            買取専用：{{ shopData.buyingPhoneNumber }}
          </p>
          <p>
            【営業時間】 <br />
            <strong v-html="shopData.businessHoursText"></strong>
          </p>
        </div>
      </div>

      <div class="title-cover">
        <img :src="`${rootUrl}/img/access01.png`" alt="徒歩、電車でお越しの方" />
      </div>
      <div v-for="(fromStationData, index) in shopData.fromStationList" :key="index" class="map-detail">
        <p>
          <span>【{{ fromStationData.title }}】</span><br />
          {{ fromStationData.description }}
        </p>
        <div class="map">
          <img :src="`${rootUrl}${fromStationData.image01Path}`" alt="地図1" />
          <img :src="`${rootUrl}${fromStationData.image02Path}`" alt="地図2" />
          <img :src="`${rootUrl}${fromStationData.image03Path}`" alt="地図3" />
          <img :src="`${rootUrl}${fromStationData.image04Path}`" alt="地図4" />
        </div>
      </div>

      <div class="title-cover">
        <img :src="`${rootUrl}/img/access02.png`" alt="お車でお越しの方" />
        <div class="map-detail">
          <p>{{ shopData.byCarData.description }}</p>
          <div class="map">
            <img :src="`${rootUrl}${shopData.byCarData.image01Path}`" alt="地図9" />
            <img :src="`${rootUrl}${shopData.byCarData.image02Path}`" alt="地図10" />
            <img :src="`${rootUrl}${shopData.byCarData.image03Path}`" alt="地図11" />
            <img :src="`${rootUrl}${shopData.byCarData.image04Path}`" alt="地図12" />
          </div>
        </div>
        <div class="parking">
          <div class="parking-text-cover">
            <div class="parking-title"><span>■</span> 駐車場ご案内</div>
            <div class="parking-description">
              <!-- ↓↓ ここはv-htmlしんどいので切り替え ↓↓ -->
              <div v-if="slectedShopId === ShopConsts.ID_NAGOYA">
                <h5>【駐車券サービス可能パーキング】</h5>
                <div class="affiliated-parking-cover">
                  <img :src="`${rootUrl}/img/npc.avif`">
                  <div class="affiliated-parking-description">
                    <h6>店舗北</h6>
                    <p>NPC駐車場 5台</p>
                  </div>
                </div>
                <div class="affiliated-parking-cover">
                  <img :src="`${rootUrl}/img/mitsui-repark.avif`">
                  <div class="affiliated-parking-description">
                    <h6>店舖南</h6>
                    <p>三井のリパーク橘1丁目第2駐車場 13台</p>
                  </div>
                </div>
                <p>2,000円以上のご購入<br />買取 • 審査のお申し込みで1時間無料</p>
              </div>
              <div v-else-if="slectedShopId === ShopConsts.ID_OKAZAKI">
                <p>メビウスビル 駐車場 ２台</p>
                <p>明大寺南交差点から東へ、坂を上がった店舗裏同ビル内に2台あります。</p>
              </div>
              <!-- ↑↑ ここはv-htmlしんどいので切り替え ↑↑ -->
            </div>
          </div>
          <img :src="`${rootUrl}${shopData.byCarData.parkingData.imagePath}`" class="parking-image" alt="パーキング" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBox from "@/components/Ui/Box.vue";
import ShopConsts from "@/consts/ShopConsts";

export default {
  name: "home-how-to-buy-in-store",
  components: {
    UiBox
  },
  props: {
    shopId: [null, String]
  },
  data() {
    return {
      // Consts
      ShopConsts: ShopConsts,

      // 
      slectedShopId: '1',
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "shopList"]),
    shopData() {
      // 
      let shopData = this.shopList.find(shopData => shopData.id === this.slectedShopId);

      // 
      let addingShopData = null;
      switch (this.slectedShopId) {
        // 
        case ShopConsts.ID_NAGOYA:
          addingShopData = {
            nearestStationText: "上前津駅より徒歩5分<br />大津通り沿いでアクセス良好",
            placeText: "大津通り沿い",
            interiorImageFileName: 'in-store04.jpg',
            fromStationList: [
              {
                title: "上前津駅から",
                description: "上前津駅6番出口より南へ徒歩6分。下前津の交差点を越え、美容KOKOの横の白い建物の2階です。",
                image01Path: `/img/access-map01.jpg`,
                image02Path: `/img/access-map02.jpg`,
                image03Path: `/img/access-map03.jpg`,
                image04Path: `/img/access-map04.jpg`,
              },
              {
                title: "東別院駅から",
                description: "東別院駅4番出口より北方向へ徒歩5分。4番出口西側の交差点をメーテレ側に渡り、スターバックスコーヒーを越えて北に直進。下茶屋公園交差点を越えて100mほど先の左手側にございます。",
                image01Path: '/img/access-map05.jpg',
                image02Path: '/img/access-map06.jpg',
                image03Path: '/img/access-map07.jpg',
                image04Path: '/img/access-map04.jpg',
              },
            ],
            byCarData: {
              description: "大津通り沿い、東別院交差点を北へ直進。下茶屋公園交差点を越えて100mほど先の左手側にございます。店舗北側100m先左側コインパーキングあり。店頭買取お持ち込みの方には駐車券サービスしております。",
              image01Path: '/img/access-map09.jpg',
              image02Path: '/img/access-map10.jpg',
              image03Path: '/img/access-map11.jpg',
              image04Path: '/img/access-map12.jpg',
              parkingData: {
                imagePath: "/img/nagoya-map.jpg",
              }
            }
          };
          break;

        // 
        case ShopConsts.ID_OKAZAKI:
          addingShopData = {
            nearestStationText: "名鉄東岡崎駅より徒歩5分<br />国道483号(電車通り)沿いで<br />アクセス良好",
            placeText: "国道483号(電車通り)沿い",
            interiorImageFileName: 'okazaki.jpg',
            fromStationList: [
              {
                title: "東岡崎駅 南出口から",
                description: "東岡崎駅南出口より出てすぐ南にある横断歩道を渡って右に曲がり、徒歩5分。信号のない交差点を左に曲がり、南に直進。明大寺南交差点の信号手前の螺旋階段のある橙色の建物の一階です。",
                image01Path: '/img/okazaki-train01.jpg',
                image02Path: '/img/okazaki-train02.jpg',
                image03Path: '/img/okazaki-train03.jpg',
                image04Path: '/img/okazaki-train04.jpg',
              },
            ],
            byCarData: {
              description: "",
              image01Path: '/img/okazaki-car01.jpg',
              image02Path: '/img/okazaki-car02.jpg',
              image03Path: '/img/okazaki-car03.jpg',
              image04Path: '/img/okazaki-car04.jpg',
              parkingData: {
                imagePath: "/img/okazaki-map.jpg",
              }
            }
          };
          break;

        // 
        default:
          // 
          break;
      }

      // 
      return { ...shopData, ...addingShopData };
    }
  },
  watch: {
    shopId: {
      handler(newShopId) {
        if (newShopId !== null) {
          this.slectedShopId = newShopId;
        } else {
          // no action.
        }
      },
      immediate: true,
    }
  }
};
</script>

<style lang="scss" scoped>
.home-how-to-buy-in-store {
  padding: 0 100px;
  padding-bottom: 80px;
  line-height: 1.4em;

  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }

  @media screen and (min-width: 581px) and (max-width: 768px) {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 580px) {
    width: 100%;
  }

  .flow {
    .title {
      color: #29aae1;
      font-size: 26px;
      font-weight: 700;
      padding: 50px 0;
    }

    table {
      &.tab {
        width: 100%;
        border-bottom: solid 1px #999;
        table-layout: fixed;

        tr {
          th {
            &.tab-button {
              // width: 50%;
              padding-bottom: 10px;
              font-size: 24px;
              font-weight: bold;
              color: #999;
              cursor: pointer;
            }

            &.active {
              color: var(--green);
              border-bottom: solid 3px var(--green);
            }
          }
        }
      }
    }

    // .tab-button-cover {
    //   display: flex;
    //   .tab-button {
    //     padding: 10px;
    //     border-bottom: solid 2px black;
    //     // border-bottom: solid 2px var(--green);
    //   }
    // }
  }

  .flow-box {
    display: flex;
    justify-content: space-between;
    height: 200px;
    margin: 100px 0 100px 0;

    @media screen and (max-width: 768px) {
      // flex-direction: column;
      height: auto;
      margin: 50px 0 30px 0;
    }

    .box {
      width: 32%;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 40px;
        justify-content: flex-start;
      }

      img {
        width: 53%;

        @media screen and (max-width: 768px) {
          width: 40%;
          margin-bottom: 20px;
        }

        // margin-bottom: 10px;
      }

      .truck {
        width: 50%;

        @media screen and (max-width: 768px) {
          width: 70%;
        }
      }

      .box {
        width: 50%;

        @media screen and (max-width: 768px) {
          width: 70%;
        }
      }

      .train {
        width: 50%;

        @media screen and (max-width: 768px) {
          width: 70%;
        }
      }

      p {
        // line-height: 1.3em;
        font-weight: bold;
        text-align: center;
        font-size: 18px;

        @media screen and (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }

  .pdf {
    padding-bottom: 100px;

    img {
      width: 60%;
    }

    a:hover {
      opacity: 0.7;
    }
  }

  .access {
    .title {
      color: var(--green);
      border-bottom: 1px solid black;
      padding-bottom: 30px;
      font-size: 28px;
      font-weight: 700;
    }

    .access-cover {
      display: flex;
      justify-content: space-between;
      padding: 50px 0;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .map {
        width: 55%;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }

      .access-detail {
        width: 40%;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }

    .map-detail,
    .access-detail {
      font-size: 18px;

      p {
        margin: 20px 0;
      }

      span {
        margin-left: -10px;
      }
    }

    .map-detail {
      padding-top: 50px;

      @media screen and (max-width: 768px) {
        padding-top: 20px;
      }

      span {
        font-weight: 700;
      }

      .map {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        img {
          width: 49%;
          margin-bottom: 2%;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }

    .parking {
      width: 100%;
      background-color: #eee;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1em;

      @media screen and (max-width: 768px) {
        display: block;
      }

      .parking-text-cover {
        margin-right: 1em;

        @media screen and (max-width: 768px) {
          margin-right: 0;
        }

        .parking-title {
          font-weight: bold;
          font-size: 24px;

          span {
            font-size: 36px;
          }
        }

        .parking-description {
          margin-top: 1em;

          h5 {
            font-weight: bold;
            margin: 0.7em 0;
          }

          .affiliated-parking-cover {
            margin-top: 1em;
            display: flex;
            align-items: center;

            img {
              width: 15%;
              margin-right: 0.5em;
            }

            .affiliated-parking-description {
              h6 {
                margin: 0;
              }

              p {
                margin: 0;
              }
            }
          }

          p {
            margin: 1em 0;
          }
        }
      }

      img {
        &.parking-image {
          width: auto;
        }
      }
    }
  }

  .title-cover {
    padding-top: 100px;

    @media screen and (max-width: 768px) {
      padding-top: 50px;
    }

    img {
      width: 50%;

      @media screen and (max-width: 768px) {
        width: 90%;
      }
    }
  }
}
</style>