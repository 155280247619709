<template>
  <div class="ctrl-group-member space-100 center-c">
    <h1>高価買取リスト カテゴリページ選択</h1>

    <div class="cover">
      <p>
        高価買取リストを追加・編集・ソート・表示設定するカテゴリページを選択してください。
      </p>
      <el-select v-model="categoryId" placeholder="選択してください">
        <el-option
          v-for="(categoryData, index) in categoryList"
          :key="index"
          :label="categoryData.name"
          :value="categoryData.id"
        >
        </el-option>
      </el-select>
      <div class="button-cover">
        <el-button @click="back" type="info" class="back">戻る</el-button>
        <el-button type="primary" @click="selected">決定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ctrl-group-member",
  components: {},
  data() {
    return {
      categoryId: "",
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    categoryList() {
      return this.$store.getters.categoryList;
      // return this.$store.getters.selectedCategoryList(["1", "2", "3", "9"]);
    },
  },
  methods: {
    selected() {
      if (this.categoryId !== "") {
        this.$router.push(`/ctrl/group-member/${this.categoryId}/members`);
      } else {
        alert("選択してください。");
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 28px !important;
}
.cover {
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
  @media screen and (max-width: 768px) {
    padding: 50px 10px;
  }
}
.el-input {
  width: 90%;
  padding-bottom: 50px;
  @media screen and (max-width: 767px) {
    width: 95%;
    font-size: 17px;
  }
}
h1 {
  text-align: center;
  padding: 30px 0;
  font-size: 22px;
}

.el-input__inner {
  padding-right: 100px;
  @media screen and (max-width: 767px) {
    padding-right: 0;
  }
}
.el-select {
  width: 50%;
  margin-top: 30px;
  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
  }
}
</style>
