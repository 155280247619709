<template>
  <div class="ctrl-columns-column-list">
    <div>
      <el-button @click="$router.push(`/ctrl/column-detail/${categoryId}/0`)" type="primary">新規追加</el-button>
    </div>
    <div class="pagination-cover">
      <el-pagination :page-size="pageSize" :pager-count="pagerCount" :total="totalCount"
        :current-page.sync="currentPage" layout="prev, pager, next" />
      <div>全 {{ totalCount | localeNum }}件</div>
    </div>
    <el-table :data="columnList" v-loading="loading">
      <el-table-column prop="id" label="ID" width="70">
      </el-table-column>
      <el-table-column label="サムネイル" width="120">
        <template slot-scope="scope">
          <div class="image-cover">
            <img v-if="scope.row.thumbnailPath === null" :src="`${rootUrl}/storage/images/default.jpg`" />
            <img v-else :src="`${rootUrl}/storage/${scope.row.thumbnailPath}`" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="タイトル">
      </el-table-column>
      <el-table-column label="ステータス" width="130">
        <template slot-scope="scope">
          <div class="status-cover">
            <el-tag v-if="scope.row.visible === true">公開済み</el-tag>
            <el-tag v-else type="info">下書き</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="" width="100">
        <template slot-scope="scope">
          <el-button @click="$router.push(`/ctrl/column-detail/${categoryId}/${scope.row.id}`)"
            type="success">編集</el-button>
        </template>
      </el-table-column>
      <el-table-column label="" width="100">
        <template slot-scope="scope">
          <el-button @click="deleteColumn(scope.row)" type="danger">削除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-cover">
      <el-pagination :page-size="pageSize" :pager-count="pagerCount" :total="totalCount"
        :current-page.sync="currentPage" layout="prev, pager, next" />
      <div>全 {{ totalCount | localeNum }}件</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "column-list",
  components: {},
  props: {
    categoryId: String
  },
  data() {
    return {
      loading: false,
      pageSize: 100,
      pagerCount: 7,
      totalCount: 0,
      currentPage: 1,
      columnList: []
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {
    currentPage(currentPage) {
      this.getProductList(currentPage);
    }
  },
  created() {
    this.getColumnList(1);
  },
  methods: {
    getColumnList(currentPage) {
      // 
      this.loading = true;

      // 
      this.currentPage = currentPage;

      // 
      let parameter = ``;
      parameter += `?category_id=${this.categoryId}`;
      parameter += `&page_size=${this.pageSize}`;
      parameter += `&current_page=${this.currentPage}`;
      this.$axios
        .get(`${this.rootUrl}/api/column/get-list${parameter}`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.totalCount = response.data.totalCount;
            this.columnList = response.data.list;
          } else {
            alert("エラーが発生しました。");
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    deleteColumn(columnData) {
      // 
      let message = ``;
      message += `ID: ${columnData.id}\n`;
      message += `タイトル: ${columnData.title}\n`;
      message += `\n`;
      message += `この買取コラムを削除します。よろしいですか？`;

      // 
      if (confirm(message)) {
        // 
        let params = new URLSearchParams();
        params.append('id', columnData.id);
        this.$axios
          .post(`${this.rootUrl}/api/column/delete`, params)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              alert("削除しました。");

              // 
              this.getColumnList(1);
            } else {
              alert("エラーが発生しました。");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // no action.
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.ctrl-columns-column-list {
  margin-top: 3.5em;

  .pagination-cover {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;

    &:nth-of-type(2) {
      margin-top: 1.5em;
    }
  }

  .el-table {
    .image-cover {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 80%;
      }
    }

    // .status-cover {
    //   display: flex;
    //   justify-content: center;
    // }
  }
}
</style>
