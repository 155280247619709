<template>
  <div class="ctrl-column-detail-column-editor">
    <BackImageTitle v-if="categoryData.categoryId !== '0'" :categoryId="categoryData.categoryId"
      :title="categoryData.title" :subTitle="null" />
    <div class="wrap1100">
      <div class="breadcrumb-cover">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">ホーム</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/columns' }">コラム一覧</el-breadcrumb-item>
          <el-breadcrumb-item>{{ categoryData.title }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="contents-cover">
        <iframe :srcdoc="categoryData.text" id="sub" :height="`${height}px`" scrolling="no" frameborder="0"></iframe>
        <ColumnSideBar :text="''" :categoryId="null" />
      </div>
    </div>

    <!--  -->
    <div v-if="categoryData.categoryId !== '0'" class="fixed-back space-150"
      :style="`background-image: url(${rootUrl}/img/back-img02.jpg);`">
      <Reason :categoryId="categoryData.categoryId" />
    </div>
    <!--  -->

    <!--  -->
    <div class="space-150 wrap960">
      <Promise :pageType="'column'" />
    </div>
    <!--  -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BackImageTitle from "@/components/Ui/BackImageTitle.vue";
import Reason from "@/components/Category/CategoryContents/Reason.vue";
import Promise from "@/components/Ui/Promise.vue";
import ColumnSideBar from "@/components/Columns/ColumnSideBar.vue";

export default {
  name: 'column-editor',
  components: {
    BackImageTitle,
    Reason,
    Promise,
    ColumnSideBar,
  },
  props: {
    columnId: String
  },
  data() {
    return {
      categoryData: {
        categoryId: "0",
        title: "",
        text: "",
        thumbnailPath: null,
      },
      height: 300
    }
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      // 
      this.$axios
        .get(`${this.rootUrl}/api/column/get-data?column_id=${this.columnId}`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            // 
            this.categoryData = response.data.data;

            // 
            setTimeout(() => {
              const sub = document.getElementById('sub');
              this.height = sub.contentWindow.document.body.scrollHeight + 100;
              console.log(this.height)
            }, 200);
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb-cover {
  margin: 1.5em 0 2.5em;
}

.contents-cover {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  iframe {
    width: 69%;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-appearance: none;
    background-color: transparent;
    display: block; /* 余白防止 */
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    @media screen and (max-width: 767px) {
      width: 100%;
      // width: 100vw;
    }
  }
}
</style>
