<template>
  <div class="all-buyback-records">
    <BuybackRecordList :categoryId="categoryId" :text="text" />

    <div class="fixed-back space-150" :style="`background-image: url(${rootUrl}/img/back-img02.jpg);`">
      <HomeReason />
    </div>

    <div class="space-150 wrap960">
      <Promise :pageType="'home'" />
    </div>

    <div class="space-150 wrap960">
      <ColumnList :categoryId="'0'" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BuybackRecordList from "@/components/AllBuybackRecords/BuybackRecordList.vue";
import HomeReason from "@/components/Home/Reason.vue";
import Promise from "@/components/Ui/Promise.vue";
import ColumnList from "@/components/Ui/ColumnList.vue";

export default {
  name: "all-buyback-records",
  components: {
    BuybackRecordList,
    HomeReason,
    Promise,
    ColumnList,
  },
  props: {},
  data() {
    return {
      categoryId: null,
      text: '',
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {
    // 
    const query = this.$route.query;
    if (query.categoryId !== undefined) {
      this.categoryId = query.categoryId;
    } else {
      // no action.
    }
    if (query.text !== undefined) {
      this.text = query.text;
    } else {
      // no action.
    }
  }
};
</script>
<style lang="scss" scoped></style>
