<template>
  <div class="ctrl">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";


export default {
  name: "ctrl",
  components: {

  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.ctrl {
  padding: 0 0 150px;
}
::v-deep.ctrl-columns {
  h1, .wrap1100 {
    margin-top: 2.5em;
  }
}
</style>
