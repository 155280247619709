<template>
  <div class="slider-box" :class="type">
    <div class="slider">
      <swiper :options="swiperOption" :style="`width:${width}%;height:${height}%;`">
        <swiper-slide v-for="box in boxes" :key="box.id">
          <div class="item-box">
            <div class="square_image">
              <img :src="`${rootUrl}/storage/${box.imgPath}`" :alt="box.name" />
            </div>
            <div class="text-cover">
              <div class="title">
                <p v-if="titleType === 'default'">{{ box.title }}</p>
                <p v-else-if="titleType === 'categoryName'">{{ getCategoryName(box.categoryId) }}</p>
              </div>
              <p class="des" v-html="box.text"></p>
              <div class="contract">
                <p class="small">【成約】</p>
                <p>{{ box.price | localeNum }}円で買取</p>
              </div>
            </div>
          </div>
        </swiper-slide>
        <!-- <div class="slide-op" v-show="button"> -->
        <div v-show="button" class="swiper-pagination" slot="pagination"></div>
        <div v-show="button" class="swiper-button-prev" slot="button-prev"></div>
        <div v-show="button" class="swiper-button-next" slot="button-next"></div>

        <!-- </div> -->
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  name: "slider-box",
  components: {},
  props: {
    type: String,
    boxes: Array,
    swiperOption: Object,
    height: Number,
    width: Number,
    titleType: String,
    button: Boolean,
  },
  // props: {
  //   path: String,
  //   type: Number,
  // },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  created() {
    //
    if (window.matchMedia("(min-width: 1700px)").matches) {
      this.swiperOption.slidesPerView = "6";
    } else if (window.matchMedia("(min-width: 1314px)").matches) {
      this.swiperOption.slidesPerView = "5";
      this.swiperOption.spaceBetween = 15;
    } else if (window.matchMedia("(min-width: 963px)").matches) {
      this.swiperOption.slidesPerView = "5";
      this.swiperOption.spaceBetween = 5;
    } else if (window.matchMedia("(min-width: 820px)").matches) {
      this.swiperOption.slidesPerView = "3";
      this.swiperOption.spaceBetween = 10;
    } else if (window.matchMedia("(min-width: 767px)").matches) {
      this.swiperOption.slidesPerView = "2";
      this.swiperOption.spaceBetween = 5;
    } else if (window.matchMedia("(min-width: 540px)").matches) {
      this.swiperOption.slidesPerView = "2";
      this.swiperOption.spaceBetween = 10;
    } else {
      this.swiperOption.slidesPerView = "1.3";
      this.swiperOption.spaceBetween = 0;
      // this.swiperOption.slidesPerView = "1";
    }

  },
  methods: {
    getCategoryName(categoryId) {
      const categoryData = this.$store.getters["categoryData"](categoryId);
      return categoryData.name;
    },
  },
  plugins: [{ src: "~/plugins/swiper", mode: "client" }],
};
</script>

<style lang="scss" scoped>
.swiper-container {
  // width: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  background-color: var(--black);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  // border-left:1px solid #fff;
}

.swiper-wrapper {
  transition-timing-function: linear !important;
}

.item-box {
  width: 100%;

  .square_image {
    position: relative;
    // min-height: 150px !important;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      object-fit: cover;
    }
  }

  .square_image::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  .text-cover {
    background-color: white;
    padding: 30px 10px;
    text-align: left;

    .title {
      font-size: 16px;
      color: white;
      display: flex;
      align-items: center;
      min-height: 55px;
      display: flex;
      align-items: flex-start;

      @media screen and (max-width: 768px) {
        font-size: 15px;
      }

      p {
        min-height: 42px;
        background-color: black;
        padding: 5px 10px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 768px) {
          padding: 0 10px;
        }
      }
    }

    .des {
      margin-top: 20px;
      font-size: 18px;
      line-height: 1.6em;
    }

    .contract {
      color: #ff0000;
      margin-top: 20px;
      font-size: 24px;
      text-align: center;


      .small {
        font-size: 18px;
        margin-bottom: 5px;
        text-align: left;
      }
    }
  }
}

// 
.high-value-buyback-records {
  .item-box {
    border: solid 1px #000;

    .text-cover {
      .des {
        min-height: 150px;

        @media screen and (max-width: 768px) {
          min-height: 80px;
          font-size: 13px;
          margin-top: 0;
        }
      }
    }
  }
}
</style>
