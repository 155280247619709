import { render, staticRenderFns } from "./ColumnDetail.vue?vue&type=template&id=18b4741a&scoped=true&"
import script from "./ColumnDetail.vue?vue&type=script&lang=js&"
export * from "./ColumnDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18b4741a",
  null
  
)

export default component.exports