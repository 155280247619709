<template>
  <div class="ctrl-columns">
    <h1>
      <span>買取コラム </span>
      <span v-if="columnId === '0'">新規追加</span>
      <span v-else>編集</span>
    </h1>
    <div class="wrap1100">
      <ColumnEditor :categoryId="categoryId" :columnId="columnId" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ColumnEditor from "@/components/Ctrl/ColumnDetail/ColumnEditor.vue";

export default {
  name: "ctrl-columns",
  components: {
    ColumnEditor,
  },
  props: {
    categoryId: String,
    columnId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped></style>
