export default Object.freeze({
    ID_CLASSIC_RECORD: '1',
    ID_JAZZ_RECORD: '2',
    ID_WJ_MUSIC_RECORD: '9',
    ID_OPEN_REEL: '3',
    ID_AUDIO_CD: '5',
    ID_AUDIO: '6',
    ID_BOOKS: '7',
    ID_AUDIO_PARTS: '8',
    ID_OTHERS: '4',
});
