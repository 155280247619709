<template>
  <div class="ctrl-column-detail-column-editor">
    <el-row type="flex" justify="center">
      <el-col :span="18">
        <el-row>
          <el-col :span="24">
            <h3>公開設定</h3>
          </el-col>
          <el-col :span="24">
            <el-switch v-model="visible" active-text="公開" inactive-text="下書き保存">
            </el-switch>
            <!-- <el-checkbox v-model="visible">公開する</el-checkbox> -->
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <h3>サムネイル画像</h3>
          </el-col>
          <el-col :span="24">
            <div>
              <input type="file" @change="fileSelected($event)" accept="image/png, image/jpeg, image/gif">
            </div>
            <ValidationErrorMessages :errorMessageList="validationErrorData.uploadFile" />
            <div v-if="thumbnailImage === null">
              <img v-if="thumbnailPath !== null" :src="`${rootUrl}/storage/${thumbnailPath}`" />
            </div>
            <div v-else>
              <img :src="thumbnailImage" />
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <h3>タイトル</h3>
          </el-col>
          <el-col :span="24">
            <el-input v-model="title"></el-input>
            <ValidationErrorMessages :errorMessageList="validationErrorData.title" />
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <h3>本文</h3>
          </el-col>
          <el-col :span="24">
            <el-tabs type="border-card">
              <el-tab-pane label="ビジュアルモード">
                <TinyMceEditor :text="text" @outputText="outputText" />
              </el-tab-pane>
              <el-tab-pane label="HTMLモード">
                <el-input v-model="text" type="textarea" :autosize="{ minRows: 24 }"></el-input>
              </el-tab-pane>
            </el-tabs>
            <ValidationErrorMessages :errorMessageList="validationErrorData.text" />
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <div class="center">
              <el-button @click="register()" type="primary">登録</el-button>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ValidationErrorMessages from "@/components/Ui/ValidationErrorMessages.vue";
import TinyMceEditor from "@/components/Ui/TinyMceEditor.vue";

export default {
  name: 'column-editor',
  components: {
    ValidationErrorMessages,
    TinyMceEditor,
  },
  props: {
    categoryId: String,
    columnId: String
  },
  data() {
    return {
      // 
      thumbnailImage: null,
      uploadFile: null,
      title: "",
      text: "",
      thumbnailPath: null,
      visible: false,

      // 
      validationErrorData: {
        uploadFile: [],
        title: [],
        text: [],
      }
    }
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {
    if (this.columnId !== '0') {
      this.getData();
    } else {
      // no action.
    }
  },
  methods: {
    outputText(text) {
      this.text = text;
    },
    getData() {
      // 
      this.$axios
        .get(`${this.rootUrl}/api/column/get-data?column_id=${this.columnId}`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.title = response.data.data.title;
            this.text = response.data.data.text;
            this.thumbnailPath = response.data.data.thumbnailPath;
            this.visible = response.data.data.visible;
          } else if (response.data.status === 400) {
            this.validationErrorData = response.data.validationErrorData;
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fileSelected(event) {
      // 
      const files = event.target.files || event.dataTransfer.files;

      // 
      let reader = new FileReader();
      reader.onload = (event) => {
        this.thumbnailImage = event.target.result;
      };
      reader.readAsDataURL(files[0]);

      // 
      event.preventDefault();
      this.uploadFile = files[0];
    },
    register() {
      // 
      const formData = new FormData();
      formData.append("id", this.columnId);
      formData.append("category_id", this.categoryId);
      if (this.uploadFile !== null) {
        formData.append("upload_file", this.uploadFile);
      } else {
        // no action.
      }
      formData.append("title", this.title);
      formData.append("text", this.text);
      formData.append("visible", this.visible === true ? '1' : '0');
      const config = { headers: { "content-type": "multipart/form-data" } };
      this.$axios
        .post(`${this.rootUrl}/api/column/register`, formData, config)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$router.go(-1);
          } else if (response.data.status === 400) {
            this.validationErrorData = response.data.validationErrorData;
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  }
}
</script>

<style lang="scss" scoped></style>
