<template>
  <div class="home-promise">
    <div class="title-cover">
      <img :src="`${rootUrl}/img/title07.png`" alt="リアリーグッドからのお約束" />
    </div>
    <div class="shop-cover">
      <p>
        レコード・オープンリール・ヴィンテージオーディオの買取は、私たちリアリーグッドに是非お任せください。レコードに愛を持った専門店による買取だからこそ実現できる、専門的で適切な査定と買取をお約束します。<br />思い出の品、大事なコレクションも安心してご依頼ください。<br />そして、買い取らせていただいたレコードは丁寧にクリーニングし、次のお客様へ責任をもって引き継がせていただきます。<br />まずはお気軽に、お電話（フリーダイヤル）、または、メールで問い合わせくださいませ。
      </p>
      <div v-if="
        pageType === 'home' ||
        pageType === 'category' ||
        pageType === 'shop'
      ">
        <div class="center">
          <img :src="`${rootUrl}/img/promise-01.jpg`" alt="店長" class="shop-top" />
        </div>
        <div class="contact">
          <div class="button">
            <a href="tel:+0120590510">
              <img :src="`${rootUrl}/img/tel-01.png`" alt="買取専用フリーダイヤル" />
            </a>
          </div>
          <div class="button">
            <router-link to="/contact">
              <img :src="`${rootUrl}/img/net-01.png`" alt="ネットで簡単申込" />
            </router-link>
          </div>
        </div>
      </div>
      <div v-else-if="
        pageType === 'column' ||
        pageType === 'article'
      ">
        <!--  -->
        <div class="title-cover detail-button">
          <router-link to="/">
            <img alt="リアリーグッドの詳細を見てみる" :src="`${rootUrl}/img/title17.png`" />
          </router-link>
        </div>
        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiBoxService from "@/components/Ui/BoxService";
export default {
  name: "home-promise",
  components: {
    // UiBoxService
  },
  props: {
    pageType: String
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-promise {
  margin-bottom: 150px;

  @media screen and (max-width: 768px) {
    margin-bottom: 100px;
  }
}

.shop-cover {
  margin-top: 100px;

  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }

  p {
    font-size: 20px;

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }

    @media screen and (min-width: 581px) and (max-width: 768px) {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: 580px) {
      width: 100%;
    }
  }

  .center {
    img {
      @media screen and (min-width: 581px) and (max-width: 768px) {
        width: 70%;
      }

      @media screen and (max-width: 580px) {
        width: 100%;
      }
    }
  }

  .shop-top {
    width: 50%;
    margin-top: 50px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .contact {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    .button {
      width: 45%;
      transition: all 0.3s ease;

      @media screen and (min-width: 581px) and (max-width: 768px) {
        width: 60%;
      }

      @media screen and (max-width: 580px) {
        width: 80%;
      }

      a {
        img {
          width: 100%;
        }
      }
    }

    .button:hover {
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      cursor: pointer;
      animation: bounce 1s ease-in-out forwards;
      animation-iteration-count: infinite;
    }
  }

  @keyframes bounce {
    0% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }

    50% {
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
    }
  }
}

.title-cover {
  text-align: center;

  &.detail-button {
    margin-top: 5em;
  }

  a:hover {
    opacity: 0.6;
  }
}
</style>