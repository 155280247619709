<template>
  <div class="category-category-contents-high-value-buyback-records">
    <div class="section-title-cover">
      <div class="section-title" :class="{mini: categoryId === '8'}">{{ categoryData.name }}の高価買取実績</div>
    </div>
    <div class="slider-cover">
      <SliderBox2 :type="'high-value-buyback-records'" :boxes="productList" :swiperOption="swiperOption" :height="100"
        :width="98" :titleType="'default'" :button="false" class="slider" />
    </div>
    <div class="button-cover">
      <div @click="$router.push('/all-buyback-records')" class="button">
        <span>全ての買取実績を見る</span>
        <span class="arrow">▶</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CategoryConsts from "@/consts/CategoryConsts";
import SliderBox2 from "@/components/Ui/SliderBox2.vue";

export default {
  name: "high-value-buyback-records",
  components: {
    SliderBox2,
  },
  props: {
    categoryId: String,
  },
  data() {
    return {
      // Consts
      CategoryConsts: CategoryConsts,

      // 
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 50,
        speed: 500,
        effect: "slide",
        loop: true,
        centeredSlides: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    categoryData() {
      return this.$store.getters.categoryData(this.categoryId);
    },
    productList() {
      return this.$store.getters.topProductList(this.categoryId);
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-cover {
  margin-top: 70px;
}

.button-cover {
  .button {
    position: relative;
    background-color: var(--green);
    color: white;
    padding: 0.3em 3em;
    font-weight: bold;
    font-size: 32px;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      font-size: 28px;
      width: 85%;
      padding: 0.7em 0.8em;
      text-align: center;
    }

    &:hover {
      opacity: 0.7;
    }

    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.5em;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}
</style>