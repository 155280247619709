<template>
  <div class="all-buyback-records-buyback-record-list">
    <!--  -->
    <div class="back-image-title">
      <div class="back-image" :style="`background-image: url(${rootUrl}/img/reallygood-pc-top-kobetsu03.jpg);`"></div>
      <div class="article-title">
        <p>買取実績一覧</p>
      </div>
    </div>
    <!--  -->

    <!--  -->
    <div class="wrap1100">
      <!--  -->
      <div class="search-box">
        <div class="category-search-box">
          <div class="title">買取ジャンル検索</div>
          <div class="category-button-cover">
            <div v-for="(categoryData, index) in categoryList" @click="setCategoryIdAndGetProductList(categoryData.id)"
              class="category-button" :class="{ active: searchConditionData.categoryId === categoryData.id }"
              :key="index">
              {{ categoryData.name }}
            </div>
          </div>
        </div>
        <div class="text-search-box">
          <div class="title">キーワード検索</div>
          <div class="input-cover">
            <el-input placeholder="キーワードを入力" v-model="searchConditionData.text"></el-input>
          </div>
        </div>
        <div class="search-button-cover">
          <el-button @click="getProductList(1)" type="primary">検索</el-button>
        </div>
      </div>
      <!--  -->

      <hr>

      <!--  -->
      <div class="buyback-record-list-cover" id="record-list-cover">
        <div class="pagination-cover">
          <el-pagination :page-size="pageSize" :pager-count="pagerCount" :total="totalCount"
            :current-page.sync="currentPage" layout="prev, pager, next" />
          <!-- <div>全 {{ totalCount | localeNum }}件</div> -->
        </div>
        <div v-loading="loading" class="buyback-record-list">
          <div v-for="(productData, index) in productList" :key="index" class="buyback-record">
            <div class="image-cover">
              <img :src="`${rootUrl}/storage/${productData.imgPath}`" :alt="productData.title">
            </div>
            <div class="text-cover">
              <div class="title-description-cover">
                <div class="title">{{ productData.title }}</div>
                <div class="description">{{ productData.text }}</div>
              </div>
              <div class="price-cover">
                <div class="reference">参考買取価格</div>
                <div class="price">{{ productData.price | localeNum }}円</div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination-cover">
          <el-pagination :page-size="pageSize" :pager-count="pagerCount" :total="totalCount"
            :current-page.sync="currentPage" layout="prev, pager, next" />
          <!-- <div>全 {{ totalCount | localeNum }}件</div> -->
        </div>
      </div>
      <!--  -->

    </div>
    <!--  -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "all-buyback-records-buyback-record-list",
  components: {},
  props: {
    categoryId: [null, String],
    text: String,
  },
  data() {
    return {
      // search
      searchConditionData: {
        categoryId: null,
        text: '',
      },

      // 
      loading: false,
      pageSize: 20,
      pagerCount: 7,
      totalCount: 0,
      currentPage: 1,
      productList: []
    };
  },
  computed: {
    ...mapGetters(["rootUrl", 'categoryList']),
  },
  watch: {
    currentPage(currentPage) {
      // 
      let margin = 123;
      if (window.matchMedia("(max-width: 767px)").matches) {
        margin = 80;
      } else {
        // no action.
      }
      const target = document.getElementById("record-list-cover");
      window.scroll({ top: target.offsetTop - margin - 20, behavior: "smooth" });

      // 
      this.getProductList(currentPage);
    }
  },
  created() {
    // 
    this.searchConditionData.categoryId = this.categoryId;
    this.searchConditionData.text = this.text;

    // 
    this.getProductList(1);
  },
  methods: {
    setCategoryIdAndGetProductList(categoryId) {
      // 
      if (this.searchConditionData.categoryId === categoryId) {
        this.searchConditionData.categoryId = null;
      } else {
        this.searchConditionData.categoryId = categoryId;
      }

      // 
      this.getProductList(1);
    },
    getProductList(currentPage) {
      // 
      this.loading = true;

      // 
      this.currentPage = currentPage;

      // 
      let parameter = ``;
      parameter += `?category_id=${this.searchConditionData.categoryId !== null ? this.searchConditionData.categoryId : ''}`;
      parameter += `&text=${this.searchConditionData.text}`;
      parameter += `&page_size=${this.pageSize}`;
      parameter += `&current_page=${this.currentPage}`;
      this.$axios
        .get(`${this.rootUrl}/api/product/get-list${parameter}`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.totalCount = response.data.totalCount;
            this.productList = response.data.list;
          } else {
            alert("エラーが発生しました。");
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.wrap1100 {
  .search-box {
    margin-top: 2em;
    padding-bottom: 1.5em;

    .title {
      text-align: center;
      font-size: 32px;
      font-weight: bold;
    }

    .category-search-box {
      .category-button-cover {
        margin-top: 1em;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .category-button {
          margin-top: 0.5em;
          padding: 0.7em 0;
          width: calc(100%/3 - 0.7em);
          border: solid 1px black;
          text-align: center;
          font-size: 20px;
          cursor: pointer;

          @media screen and (max-width: 768px) {
            width: calc(33% - 0.2em);
            font-size: 7px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 4em;
          }

          &.active {
            color: white;
            background-color: var(--green);
          }
        }
      }
    }

    .text-search-box {
      margin-top: 2em;

      .input-cover {
        margin-top: 1em;
        text-align: center;

        .el-input {
          width: 50%;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }

    .search-button-cover {
      margin-top: 2em;
      text-align: center;
    }
  }

  .buyback-record-list-cover {
    margin-top: 1.5em;

    .buyback-record-list {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;

      .buyback-record {
        $margin-side: 5px;
        width: calc(25% - $margin-side * 2);
        margin: 0.5em $margin-side 0.5em;
        border: solid 1px black;

        @media screen and (max-width: 768px) {
          width: calc(50% - $margin-side * 2);
        }

        $image-cover-height: 190px;

        .image-cover {
          overflow: hidden;

          @media screen and (max-width: 768px) {
            height: $image-cover-height;
          }

          img {
            width: 100%;
          }
        }

        .text-cover {
          padding: 0.5em;

          @media screen and (max-width: 768px) {
            padding-top: 0;
            height: calc(100% - $image-cover-height);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }

          .title-description-cover {
            min-height: 250px;

            @media screen and (max-width: 768px) {
              min-height: 120px;
            }

            .title {
              color: white;
              background-color: black;
              display: inline-block;
              padding: 0.1em 0.8em;

              @media screen and (max-width: 768px) {
                font-size: 14px;
                font-weight: bold;
                line-height: 1.5em;
              }
            }

            .description {
              margin-top: 0.5em;
              overflow-wrap: break-word;

              @media screen and (max-width: 768px) {
                font-size: 12px;
                font-weight: bold;
                line-height: 1.5em;
              }
            }
          }

          .price-cover {
            font-weight: bold;
            color: red;
            margin-top: 1em;

            .reference {
              @media screen and (max-width: 768px) {
                line-height: 1.1em;
                font-size: 16px;
              }
            }

            .price {
              font-size: 30px;
              text-align: right;

              @media screen and (max-width: 768px) {
                margin-top: 5px;
                line-height: 1.1em;
                font-size: 24px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
