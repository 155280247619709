<template>
  <div class="classic-record">
    <ArticleContents :articleId="articleId" />
  </div>
</template>

<script>
import ArticleContents from "@/components/Category/ArticleContents.vue";

export default {
  name: "ClassicRecord",
  components: {
    ArticleContents,
  },
  props: {
    articleId: String,
  },
  data() {
    return {
      // 
    };
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
