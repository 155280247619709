import { render, staticRenderFns } from "./IfYouBuy.vue?vue&type=template&id=02653564&scoped=true&"
import script from "./IfYouBuy.vue?vue&type=script&lang=js&"
export * from "./IfYouBuy.vue?vue&type=script&lang=js&"
import style0 from "./IfYouBuy.vue?vue&type=style&index=0&id=02653564&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02653564",
  null
  
)

export default component.exports