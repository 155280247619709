var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-promise"},[_c('div',{staticClass:"title-cover"},[_c('img',{attrs:{"src":(_vm.rootUrl + "/img/title07.png"),"alt":"リアリーグッドからのお約束"}})]),_c('div',{staticClass:"shop-cover"},[_vm._m(0),(
      _vm.pageType === 'home' ||
      _vm.pageType === 'category' ||
      _vm.pageType === 'shop'
    )?_c('div',[_c('div',{staticClass:"center"},[_c('img',{staticClass:"shop-top",attrs:{"src":(_vm.rootUrl + "/img/promise-01.jpg"),"alt":"店長"}})]),_c('div',{staticClass:"contact"},[_c('div',{staticClass:"button"},[_c('a',{attrs:{"href":"tel:+0120590510"}},[_c('img',{attrs:{"src":(_vm.rootUrl + "/img/tel-01.png"),"alt":"買取専用フリーダイヤル"}})])]),_c('div',{staticClass:"button"},[_c('router-link',{attrs:{"to":"/contact"}},[_c('img',{attrs:{"src":(_vm.rootUrl + "/img/net-01.png"),"alt":"ネットで簡単申込"}})])],1)])]):(
      _vm.pageType === 'column' ||
      _vm.pageType === 'article'
    )?_c('div',[_c('div',{staticClass:"title-cover detail-button"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"alt":"リアリーグッドの詳細を見てみる","src":(_vm.rootUrl + "/img/title17.png")}})])],1)]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" レコード・オープンリール・ヴィンテージオーディオの買取は、私たちリアリーグッドに是非お任せください。レコードに愛を持った専門店による買取だからこそ実現できる、専門的で適切な査定と買取をお約束します。"),_c('br'),_vm._v("思い出の品、大事なコレクションも安心してご依頼ください。"),_c('br'),_vm._v("そして、買い取らせていただいたレコードは丁寧にクリーニングし、次のお客様へ責任をもって引き継がせていただきます。"),_c('br'),_vm._v("まずはお気軽に、お電話（フリーダイヤル）、または、メールで問い合わせくださいませ。 ")])}]

export { render, staticRenderFns }