<template>
  <div class="side-bar-cover">
    <div class="search-box">
      <el-input placeholder="Please input" v-model="searchConditionData.text" class="input-with-select" clearable>
        <el-button @click="searchByText()" slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div>
    <div class="category-box">
      <div class="box-title">カテゴリー</div>
      <div class="buttons-cover">
        <div v-for="(categoryData, index) in allCategoryList" @click="searchByCategoryId(categoryData.id)" :key="index"
          class="category-button" :class="{ 'selected': categoryId === categoryData.id }"><i
            class="el-icon-success"></i>{{ categoryData.name }}</div>
      </div>
    </div>
    <div class="recent-posts-box">
      <div class="box-title">最近の投稿</div>
      <div class="buttons-cover">
        <div v-for="(recentColumnData, index) in recentColumnList" @click="jump(recentColumnData.id)"
          class="column-title" :key="index">{{
            recentColumnData.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'column-list',
  components: {
  },
  props: {
    text: String,
    categoryId: [null, String],
  },
  data() {
    return {
      // 
      recentColumnList: [],

      // 
      searchConditionData: {
        text: ''
      }
    }
  },
  computed: {
    ...mapGetters(['rootUrl', 'allCategoryList']),
  },
  watch: {
    currentPage(currentPage) {
      this.getColumnList(currentPage);
    }
  },
  created() {
    // 
    this.getRecentColumnList();

    // 
    this.searchConditionData.text = this.text;
  },
  methods: {
    getRecentColumnList() {
      let parameter = ``;
      parameter += `?category_id=`;
      parameter += `&text=`;
      parameter += `&visible_only=1`;
      parameter += `&page_size=5`;
      parameter += `&current_page=1`;
      this.$axios
        .get(`${this.rootUrl}/api/column/get-list${parameter}`)
        .then((response) => {
          console.log(response);
          this.recentColumnList = response.data.list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchByText() {
      // 
      let categoryIdParameter = '';
      if (this.categoryId !== null) {
        categoryIdParameter = `&categoryId=${this.categoryId}`;
      } else {
        // no action.
      }

      // 
      location.href = `/columns?text=${this.searchConditionData.text}${categoryIdParameter}`;
    },
    searchByCategoryId(categoryId) {
      // 
      let categoryIdParameter = '';
      if (this.categoryId !== categoryId) {
        categoryIdParameter = `?categoryId=${categoryId}`;
      } else {
        // no action.
      }

      // 
      location.href = `/columns${categoryIdParameter}`;
    },
    jump(columnId) {
      location.href = `/column-detail/${columnId}`
    }
  }
}
</script>

<style lang="scss" scoped>
.side-bar-cover {
  width: 29%;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 2.5em;
  }

  .search-box,
  .category-box,
  .recent-posts-box {
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    margin-bottom: 2em;
  }

  .search-box {
    padding: 20px;
    padding-bottom: 2em;
  }

  .category-box,
  .recent-posts-box {
    $brown: #8A4949;

    .box-title {
      background-color: $brown;
      color: white;
      padding: 0.3em 1em;
      font-weight: bold;
    }

    .buttons-cover {
      padding: 20px;

      .category-button {
        color: white;
        background-color: $brown;
        padding: 0.5em 1em;
        border: solid 1px #ccc;
        border-bottom: none;
        font-size: 14px;

        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }

        &.selected {
          color: black;
          background-color: white;

          .el-icon-success {
            color: $brown;
          }
        }

        .el-icon-success {
          font-size: 12px;
          margin-right: 7px;
        }
      }

      .column-title {
        font-weight: bold;
        border-top: solid 1px #ccc;
        padding: 0.7em;
        word-wrap: break-word;

        @media screen and (max-width: 767px) {
          font-size: 14px;
          font-weight: normal;
          line-height: 1.5em;
          padding: 1em 0.5em;
        }

        &:last-child {
          border-bottom: solid 1px #ccc;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
