<template>
  <div class="ctrl-login">
    <div class="wrap1100">
      <CategorySelector :pageCode="pageCode" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CategorySelector from "@/components/Ctrl/SelectCategory/CategorySelector.vue";

export default {
  name: "ctrl-login",
  components: {
    CategorySelector,
  },
  props: {
    pageCode: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped></style>
