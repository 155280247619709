<template>
  <div class="home-how-to-buy">
    <div class="title-cover">
      <img :src="`${rootUrl}/img/title03.png`" alt="選べる３つの買取方法" />
    </div>
    <p class="space-50">
      リアリーグッドでは、「宅配・出張・店頭」の3つの方法で買い取りを行っています。全国どこからでも、
      買取査定のご依頼を承っております。
    </p>
    <div id="delivery" class="space-link">
      <div class="buying-outer space-100" id="delivery">
        <div class="buying space-50">
          <div class="buying-left center-c">
            <img :src="`${rootUrl}/img/delivery.png`" alt="宅配買取" class="del-img" />
            <p>宅配買取</p>
          </div>

          <div class="buying-right">
            <p class="sub-title">宅配買取</p>
            <p>
              全国どこからでも買取します！箱に詰めて送るだけ！送料無料で
              ご自宅から発送！梱包キットもご用意しております。
            </p>
          </div>
        </div>

        <div class="buying-detail-cover">
          <div class="buying-detail space-50" @click="openDetailNo1 = !openDetailNo1">
            <p>▼詳細を見る▼</p>
          </div>
          <transition>
            <div v-show="openDetailNo1" style="background-color: #ffffff">
              <HomeHowToBuyDelivery />
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div id="instore" class="instore">
      <div class="buying-outer space-100">
        <div class="buying space-50">
          <div class="buying-left center-c">
            <img :src="`${rootUrl}/img/mobile-service.png`" alt="宅配買取" />
            <p>出張買取</p>
          </div>

          <div class="buying-right">
            <p class="sub-title">出張買取</p>
            <p><span v-if="shopId !== null">{{ shopData.strongAreaData.addingDescription
                }}</span>愛知・岐阜・三重の東海エリアはお任せ下さい！<span v-if="shopId === null">即日出張ご相談下さい！</span>引越しや遺品整理にも 素早く対応します。</p>
          </div>
        </div>
        <div v-if="shopId !== null" class="strong-buying-area">
          <h3>買取強化エリア</h3>
          <div class="strong-buying-area-image-cover">
            <img :src="`${rootUrl}${shopData.strongAreaData.imagePath}`" alt="買取強化エリア" />
          </div>
          <p>以下のエリアは買取強化エリアのため、最速で翌日訪問が可能な場合あります。</p>
          <p>{{ shopData.strongAreaData.targetAreaText }}</p>
        </div>
        <div class="buying-detail space-50" @click="openDetailNo2 = !openDetailNo2">
          <p>▼詳細を見る▼</p>
        </div>
        <transition>
          <div v-show="openDetailNo2" style="background-color: #ffffff">
            <HomeHowToBuyMobileService :shopId="shopId" />
          </div>
        </transition>
      </div>
    </div>
    <div id="mobileservice" class="mobileservice">
      <div class="buying-outer space-100">
        <div class="buying space-50">
          <div class="buying-left center-c">
            <img :src="`${rootUrl}/img/in-store.png`" alt="店頭買取" />
            <p>店頭買取</p>
          </div>

          <div class="buying-right">
            <p class="sub-title">店頭買取</p>
            <p>
              <span v-if="shopId === null">名古屋店は上前津駅より徒歩5分。大津通り沿いでアクセス良好！岡崎店は名鉄東岡崎駅より徒歩5分。国道483号(電車通り)沿いでアクセス良好！</span>
              <span v-else>
                <span v-if="shopId === ShopConsts.ID_NAGOYA">上前津駅より徒歩5分。大津通り沿いでアクセス良好！</span>
                <span v-else-if="shopId === ShopConsts.ID_OKAZAKI">名鉄東岡崎駅より徒歩5分。国道483号(電車通り)沿いでアクセス良好！</span>
              </span>
              <span>当日その場で即日お支払い！スピード査定、1点からお申し込み可能です。</span>
            </p>
          </div>
        </div>
        <div class="buying-detail space-50" @click="openDetailNo3 = !openDetailNo3">
          <p>▼詳細を見る▼</p>
        </div>
        <transition>
          <div v-show="openDetailNo3" style="background-color: #ffffff">
            <HomeHowToBuyInStore :shopId="shopId" />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeHowToBuyDelivery from "@/components/Home/HowToBuy/Delivery.vue";
import HomeHowToBuyMobileService from "@/components/Home/HowToBuy/MobileService.vue";
import HomeHowToBuyInStore from "@/components/Home/HowToBuy/InStore.vue";
import ShopConsts from "@/consts/ShopConsts";

export default {
  name: "home-how-to-buy",
  components: {
    HomeHowToBuyDelivery,
    HomeHowToBuyMobileService,
    HomeHowToBuyInStore,
  },
  props: {
    shopId: [null, String]
  },
  data() {
    return {
      // Consts
      ShopConsts: ShopConsts,

      // 
      openDetailNo1: false,
      openDetailNo2: false,
      openDetailNo3: false,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    shopData() {
      // 
      let shopData = null;
      switch (this.shopId) {
        // 
        case ShopConsts.ID_NAGOYA:
          shopData = {
            strongAreaData: {
              addingDescription: '名古屋市と尾張～岐阜エリアは買取強化エリアで、最速で翌日訪問が可能な場合あります！',
              imagePath: '/img/nagoya-area.jpg',
              targetAreaText: '名古屋市、東海市、日進市、長久手市、尾張旭市、瀬戸市、春日井市、小牧市、犬山市、江南市、扶桑町、大口町、一宮市、稲沢市、清須市、北名古屋市、岩倉市、あま市、大治町、津島市、愛西市、弥富市、飛島村、蟹江町、桑名市、四日市市、岐阜市、羽島市、大垣市、多治見市'
            }
          };
          break;

        // 
        case ShopConsts.ID_OKAZAKI:
          shopData = {
            strongAreaData: {
              addingDescription: '岡崎市と三河～静岡西エリアは買取強化エリアで、最速で翌日訪問が可能な場合あります！',
              imagePath: '/img/okazaki-area.jpg',
              targetAreaText: '安城市、新城市、豊川市、蒲郡市、豊橋市、幸田町、知立市、刈谷市、西尾市、碧南市、高浜市、半田市、田原市、常滑市、知多市、東浦町、大府市、設楽町、豊根村、浜松市'
            }
          };
          break;

        // 
        default:
          // 
          break;
      }

      // 
      return shopData;
    }
  },
};
</script>

<style lang="scss" scoped>
.home-how-to-buy {
  .title-cover {
    @media screen and (max-width: 768px) {
      padding-top: 50px;
    }
  }

  .buying-outer {
    @media screen and (max-width: 768px) {
      padding-bottom: 50px;
    }

    .buying {
      display: flex;
      justify-content: space-between;

      // @media screen and (max-width: 768px) {
      //   flex-direction: column;
      // }

      .buying-left {
        width: 200px;

        // @media screen and (min-width: 581px) and (max-width: 768px) {
        //   width: 65%;
        //   margin-left: auto;
        //   margin-right: auto;
        // }

        @media screen and (max-width: 768px) {
          width: 65%;
          margin-right: 10px;
        }

        img {
          width: 100%;
          margin-bottom: 20px;

          @media screen and (max-width: 768px) {
            width: 75%;
            margin-bottom: 10px;
          }
        }

        .del-img {
          width: 75%;

          @media screen and (max-width: 768px) {
            width: 65%;
          }
        }

        p {
          font-size: 46px;
          font-weight: 700;

          @media screen and (max-width: 768px) {
            font-size: 32px;
          }
        }
      }

      .buying-right {
        width: calc(95% - 200px);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        @media screen and (max-width: 768px) {
          margin-top: 50px;
        }

        @media screen and (min-width: 581px) and (max-width: 768px) {
          width: 70%;
          margin-left: auto;
          margin-right: auto;
        }

        @media screen and (max-width: 580px) {
          width: 100%;
        }

        .sub-title {
          color: var(--orange);
          font-size: 32px;
          font-weight: 700;
          letter-spacing: 0.1em;

          @media screen and (max-width: 768px) {
            font-size: 28px;
            margin-bottom: 0.5em;
          }
        }
      }
    }

    .strong-buying-area {
      h3 {
        text-align: center;
        margin-top: 1.5em;
        font-weight: bold;
        font-size: 26px;
      }

      .strong-buying-area-image-cover {
        margin-top: 0.5em;
        display: flex;
        justify-content: center;
      }

      p {
        margin: 1em 0;
      }
    }

    .buying-detail {
      text-align: center;
      font-weight: 700;
      border-bottom: 1px solid black;
    }

    .buying-detail:hover {
      cursor: pointer;
      color: var(--orange);
    }
  }

  .v-enter {
    opacity: 0;
  }

  .v-enter-active {
    transition: opacity 1s;
  }

  .v-enter-to {
    opacity: 1;
  }

  .v-leave {
    opacity: 2;
  }

  .v-leave-active {
    transition: opacity 0.5s;
  }

  .v-leave-to {
    opacity: 0;
  }
}

.instore,
.mobileservice,
.delivery {
  padding-top: 5px;

  @media screen and (max-width: 768px) {
    padding-top: 0px;
  }
}

.space-link {
  padding-top: 10px;

  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
}
</style>