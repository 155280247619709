<template>
  <div class="layout-header">
    <div class="header">
      <div class="header-cover">
        <div class="to-top" @click="top">
          <img :src="`${rootUrl}/img/logo1.png`" alt="reallygood-ロゴ" />
        </div>

        <div class="menu-box">
          <div class="menu">
            <router-link to="/" v-scroll-to="`#aboutus`"><span>初めての方へ</span></router-link>
          </div>

          <div class="menu">
            <router-link to="/" v-scroll-to="`#buyback`"><span>買取情報</span></router-link>
          </div>

          <div class="menu" style="cursor: pointer" v-on:mouseover="mouseOverAction" v-on:mouseleave="mouseLeaveAction">
            <span>買取品目</span>
            <ul class="child-menu" v-if="hoverFlag">
              <li v-for="(categoryData, index) in categoryList" :key="index">
                <router-link :to="`/${categoryData.code}`" v-scroll-to="{
                  el: '#app',
                  container: 'body',
                  duration: 750,
                  easing: 'ease',
                  offset: 0,
                }">
                  <p>{{ categoryData.name }}</p>
                </router-link>
              </li>
            </ul>
          </div>

          <div class="menu" style="cursor: pointer" v-on:mouseover="mouseOverAction2"
            v-on:mouseleave="mouseLeaveAction2">
            <span>買取方法</span>
            <ul class="child-menu" v-if="hoverFlag2">
              <li style="padding: 10px 15px">
                <a href="#">
                  <router-link to="/" v-scroll-to="`#delivery`">
                    <p>宅配買取</p>
                  </router-link></a>
              </li>
              <li style="padding: 10px 15px">
                <router-link to="/" v-scroll-to="`#instore`">
                  <p>出張買取</p>
                </router-link>
              </li>
              <li style="padding: 10px 15px">
                <router-link to="/" v-scroll-to="`#mobileservice`">
                  <p>店頭買取</p>
                </router-link>
              </li>
            </ul>
          </div>

          <div class="menu">
            <router-link to="/" v-scroll-to="`#voice`"><span>お客様の声</span></router-link>
          </div>

          <div class="menu">
            <router-link to="/" v-scroll-to="`#qa`"><span>よくある質問</span></router-link>
          </div>

          <div class="menu" style="border-right: none;cursor: pointer" @mouseover="mouseOverAction3"
            v-on:mouseleave="mouseLeaveAction3">
            <span>店舗情報</span>
            <ul class="child-menu" v-if="hoverFlag3">
              <li style="padding: 10px 15px">
                <router-link to="/nagoya">
                  <p>名古屋店</p>
                </router-link>
              </li>
              <li style="padding: 10px 15px">
                <router-link to="/okazaki">
                  <p>岡崎店</p>
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="contact">
          <a href="tel:+0120590510">
            <img :src="`${rootUrl}/img/top-tel.png`" alt="reallygood-ロゴ" /></a>
        </div>

        <div class="hamburger">
          <UiDrawer />
        </div>
      </div>
    </div>
    <div class="pc-fixed" v-show="path !== `/contact`">
      <div class="cover">
        <router-link to="/contact">
          <img :src="`${rootUrl}/img/net-02.png`" alt="ネットで簡単申込" /></router-link>
      </div>
      <div class="cover">
        <a href="tel:+0120590510">
          <img :src="`${rootUrl}/img/tel-02.png`" alt="買取専用フリーダイヤル" /></a>
      </div>
    </div>
    <div class="mobile-fixed">
      <div class="cover">
        <router-link to="/contact">
          <img :src="`${rootUrl}/img/net-03.png`" alt="ネットで簡単申込" style="vertical-align: bottom" /></router-link>
      </div>
      <div class="cover2">
        <a href="tel:+0120590510">
          <img :src="`${rootUrl}/img/tel-03.png`" alt="買取専用フリーダイヤル" style="vertical-align: bottom" /></a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiDrawer from "@/components/Ui/Drawer.vue";
export default {
  name: "LayoutHeader",
  components: {
    UiDrawer,
  },
  props: { path: String },
  data() {
    return {
      openContent: false,
      openContent2: false,
      hoverFlag: false,
      hoverFlag2: false,
      hoverFlag3: false,
    };
  },
  computed: {
    ...mapGetters(["rootUrl", 'categoryList']),
  },
  // mounted: function () {
  //   window.addEventListener(
  //     "click",
  //     function () {
  //       this.toggleOpenContent("other");
  //     }.bind(this)
  //   );
  // },
  methods: {
    mouseOverAction() {
      this.hoverFlag = true;
    },
    mouseLeaveAction() {
      this.hoverFlag = false;
    },
    mouseOverAction2() {
      this.hoverFlag2 = true;
    },
    mouseLeaveAction2() {
      this.hoverFlag2 = false;
    },
    mouseOverAction3() {
      this.hoverFlag3 = true;
    },
    mouseLeaveAction3() {
      this.hoverFlag3 = false;
    },
    top() {
      if (this.$route.path !== "/") {
        //遷移する
        this.$router.push("/");
      } else {
        this.$scrollTo("#app", 500, { easing: "ease" });
        // alert('トップページ');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 123px;
  position: fixed;
  z-index: 999;
  background-color: var(--yellow2);

  @media screen and (max-width: 767px) {
    height: 80px;
  }

  .header-cover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    min-width: 1320px;

    @media screen and (min-width: 768px) and (max-width: 1300px) {
      min-width: 961px;
    }

    @media screen and (max-width: 961px) {
      min-width: initial;
      width: 90%;
    }
  }

  .to-top {
    cursor: pointer;
    margin-left: 2%;
    width: 240px;

    @media screen and (min-width: 911px) and (max-width: 1300px) {
      margin-right: 20px;
    }

    @media screen and (max-width: 961px) {
      width: 200px;
    }
  }

  .to-top:hover {
    opacity: 0.7;
  }

  .menu-box {
    display: flex;
    position: relative;

    a {
      text-decoration: none;
      color: black;
    }

    .menu {
      padding: 10px 15px;
      border-right: 2px solid black;
      font-size: 17px;
      font-family: "Kiwi Maru", serif;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      height: 40px;

      @media screen and (max-width: 961px) {
        display: none;
      }
    }

    .child-menu {
      position: absolute;
      top: 40px;
      display: block;
      background: var(--yellow2);

      li {
        padding: 10px 10px;
        font-size: 17px;
        font-weight: 400;
        text-align: center;

        &:hover {
          font-weight: bold;
        }
      }
    }
  }

  .menu:hover {
    font-weight: bold;
  }

  .contact {
    width: 240px;
    margin-right: 40px;

    @media screen and (max-width: 1300px) {
      display: none;
    }

    a:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  .hamburger {
    display: none;

    @media screen and (max-width: 961px) {
      display: initial;
    }
  }
}

.pc-fixed {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 80px;
  z-index: 999;

  // height: 100%;
  img {
    height: 48vh;
    object-fit: contain;
  }

  @media screen and (max-width: 819px) {
    bottom: 10px;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  @media screen and (max-width: 819px) {
    display: none;
  }
}

.mobile-fixed {
  display: none;

  @media screen and (max-width: 819px) {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    z-index: 999;
    width: 100vw;

    .cover,
    .cover2 {
      height: 10%;

      a {
        margin: 0;

        img {
          display: block;
          margin: 0;
          object-fit: contain;
          object-position: 0 10px;

          @media screen and (max-width: 540px) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
</style>
