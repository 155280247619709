<template>
  <div class="column-detail">
    <ColumnContents :columnId="columnId" />
  </div>
</template>

<script>
import ColumnContents from "@/components/ColumnDetail/ColumnContents.vue";

export default {
  name: "ClassicRecord",
  components: {
    ColumnContents,
  },
  props: {
    columnId: String
  },
  data() {
    return {
      // 
    };
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
