<template>
  <div class="test-register-member wrap960 space-100">
    <h1>{{ categoryData.name }}<br />高価買取リスト 追加・編集・ソート・表示設定</h1>
    <div class="record-box">
      <p class="explanation">コンテンツに掲載する商品</p>
      <div class="item-box-cover">
        <draggable v-model="memberList" element="div" :options="{ animation: 300 }" class="doraggu">
          <div class="item-box" v-for="(memberData, memberIndex) in memberList" :key="memberIndex">
            <div class="member-list">
              <p>{{ memberData.name }}</p>
              <div class="button-cover2">
                <router-link
                  :to="`/ctrl/group-member/${categoryId}/member-detail/${memberData.groupId}/${memberData.id}`">
                  <el-button type="primary" style="
                      vertical-align: top;
                      height: 100%;
                      margin-right: 10px;
                    ">確認・修正</el-button></router-link>
                <el-button @click="deleteMember(memberData.id)">削除</el-button>
                <el-checkbox v-model="memberData.visible" />
              </div>
            </div>
          </div>
        </draggable>
        <div class="button-cover">
          <el-button @click="back" type="info" class="back">戻る</el-button>
          <router-link :to="`/ctrl/group-member/${categoryId}/member-detail/${this.defaultGroupId}/0`">
            <el-button type="danger">新規作成</el-button></router-link>
          <el-button @click="registerVisbleConfig()">確定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "test-register-member",
  components: {
    draggable,
  },
  props: {
    categoryId: String,
  },
  data() {
    return {
      memberList: []
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    categoryData() {
      return this.$store.getters.categoryData(this.categoryId);
    },
    defaultGroupId() {
      return this.categoryData.groupList[0].id;
    },
  },
  created() {
    // 
    this.memberList = this.categoryData.memberList;
  },
  methods: {
    deleteMember(memberId) {
      if (confirm("本当に削除してもいいですか？")) {
        let params = new URLSearchParams();
        params.append("member_id", memberId);
        this.$axios
          .post(`${this.rootUrl}/api/member/delete-member`, params)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              this.$store.dispatch("setCategoryList");
              return;
            } else {
              alert("エラーが発生しました。");
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return;
      }
    },
    registerVisbleConfig() {
      //
      let visibleList = [];
      let sort = 0;
      this.memberList.forEach((memberData) => {
        visibleList.push({
          memberId: memberData.id,
          visible: memberData.visible,
          sort: sort
        });
        sort++;
      });
      console.log(visibleList);

      //
      let params = new URLSearchParams();
      params.append("visible_list", JSON.stringify(visibleList));
      this.$axios
        .post(`${this.rootUrl}/api/member/register-visible-config`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            alert('変更しました。');
            this.$store.dispatch("setCategoryList");
            return;
          } else if (
            response.data.status === 401 ||
            response.data.status === 400
          ) {
            alert(response.data.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.record-box {
  margin-top: 3em;

  .sub-title {
    border-bottom: 1px solid black;
    margin-bottom: 30px;

    img {
      width: 50px;
      vertical-align: middle;
      margin-right: 10px;
    }

    span {
      font-weight: 700;
      vertical-align: middle;
      font-size: 32px;
    }
  }

  .item-box {
    width: 100%;
  }

  .item-box-cover {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .member-list {
    background-color: #f7f7f7;
    padding: 10px 20px;
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    .button-cover2 {
      display: flex;

      @media screen and (max-width: 768px) {
        justify-content: space-between;
        margin-top: 20px;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    p {
      width: 100%;

      // width: 400px;
      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }
  }

  .explanation {
    width: 40%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .el-checkbox {
    margin-left: 15px;
  }

  .doraggu {
    width: 100%;
  }
}
</style>