import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  setTitle,
  setDescription
} from '@/mixins/index.js'
import CategoryConsts from "@/consts/CategoryConsts";

// ページ一覧
import Home from '../views/Home.vue'
import Category from '../views/Category.vue'
import CategoryArticle from '../views/Category/Article.vue'
import AllBuybackRecords from '../views/AllBuybackRecords.vue'
// import ClassicRecord from '../views/ClassicRecord.vue'
// import ClassicRecordArticle from '../views/ClassicRecord/Article.vue'
// import JazzRecord from '../views/JazzRecord.vue'
// import JazzRecordArticle from '../views/JazzRecord/Article.vue'
// import WjMusicRecord from '../views/WjMusicRecord.vue'
// import WjMusicRecordArticle from '../views/WjMusicRecord/Article.vue'
// import OpenReel from '../views/OpenReel.vue'
// import OpenReelArticle from '../views/OpenReel/Article.vue'
import Contact from '../views/Contact.vue'
import Privacy from '../views/Privacy.vue'
import Ordercontract from '../views/Ordercontract.vue'
// import Newcomer from '../views/Newcomer.vue'
// import Voice from '../views/Voice.vue'
// import StoreInformation from '../views/StoreInformation.vue'
import ShopNagoya from '../views/Shop/Nagoya.vue'
import ShopOkazaki from '../views/Shop/Okazaki.vue'
import Columns from '../views/Columns.vue';
import ColumnDetail from '../views/ColumnDetail.vue';

// 管理画面
import Ctrl from '../views/Ctrl.vue'
import CtrlHome from '../views/Ctrl/Home.vue'
import CtrlLogin from '../views/Ctrl/Login.vue'
import CtrlChangePassword from '../views/Ctrl/ChangePassword.vue'
import CtrlSelectCategory from '../views/Ctrl/SelectCategory.vue'
import CtrlGroupMember from '../views/Ctrl/GroupMember.vue'
import CtrlGroupMemberCategory from '../views/Ctrl/GroupMemberCategory.vue'
import CtrlGroupMemberCategoryMembers from '../views/Ctrl/GroupMemberCategory/Members.vue'
import CtrlGroupMemberCategoryMemberDetail from '../views/Ctrl/GroupMemberCategory/MemberDetail.vue'
import CtrlArticle from '../views/Ctrl/Article.vue'
import CtrlArticleCategory from '../views/Ctrl/ArticleCategory.vue'
import CtrlArticleArticles from '../views/Ctrl/ArticleCategory/Articles.vue'
import CtrlArticleCategoryArticleDetail from '../views/Ctrl/ArticleCategory/ArticleDetail.vue'
import CtrlProduct from '../views/Ctrl/Product.vue'
import CtrlProductCategory from '../views/Ctrl/ProductCategory.vue'
import CtrlProductCategoryProducts from '../views/Ctrl/ProductCategory/Products.vue'
import CtrlProductCategoryProductDetail from '../views/Ctrl/ProductCategory/ProductDetail.vue'
import CtrlResults from '../views/Ctrl/Results.vue'
import CtrlResultsDetail from '../views/Ctrl/ResultDetail.vue'
import CtrlColumns from '../views/Ctrl/Columns.vue'
import CtrlColumnDetail from '../views/Ctrl/ColumnDetail.vue'
import CtrlMembers from '../views/Ctrl/Members.vue'



// テスト
// import Test from '../views/Test.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'home',
  component: Home,
  meta: {
    title: 'レコード/オープンリール/オーディオ買取ならリアリーグッド',
    description: '名古屋市、岡崎市に店舗を構えるレコード・オーディオ買取・販売専門店リアリーグッドでは、クラシックやジャズ、洋楽、邦楽のレコード、オーディオ機器やオープンリールを強化買取中です。高価買取実績が多数あります。専門的だからこそ実現できる適切な査定で、納得の買取金額をご提示させていただきます。宅配、出張、店頭持ち込みでの買取が可能となっております。'
  }
},
{
  path: '/contact',
  name: 'contact',
  component: Contact,
  meta: {
    title: 'お問い合わせ｜リアリーグッド',
    description: ''
  }
},
{
  path: '/classic-record',
  name: 'classic-record',
  component: Category,
  props: {
    categoryId: String(CategoryConsts.ID_CLASSIC_RECORD)
  },
  meta: {
    title: 'クラシックレコードの高価買取｜リアリーグッド',
    description: 'クラシックレコードの買取なら、レコード専門店であり、かつ、クラシックレコードに特化したリアリーグッドをご利用ください。'
  },
},
{
  path: '/classic-record/article/:articleId',
  name: 'classic-record-article',
  component: CategoryArticle,
  props: route => ({
    articleId: String(route.params.articleId)
  }),
  meta: {
    title: 'クラシックレコードの高価買取｜リアリーグッド',
    description: ''
  }
},
{
  path: '/jazz-record',
  name: 'jazz-record',
  component: Category,
  props: {
    categoryId: String(CategoryConsts.ID_JAZZ_RECORD)
  },
  meta: {
    title: 'ジャズレコードの高価買取｜リアリーグッド',
    description: 'ジャズレコードの買取なら、レコード専門店であり、かつ、ジャズレコードに特化したリアリーグッドをご利用ください。'
  }
},
{
  path: '/jazz-record/article/:articleId',
  name: 'jazz-record-article',
  component: CategoryArticle,
  props: route => ({
    articleId: String(route.params.articleId)
  }),
  meta: {
    title: 'ジャズレコードの高価買取｜リアリーグッド',
    description: ''
  }
},
{
  path: '/wj-music-record',
  name: 'wj-music-record',
  component: Category,
  props: {
    categoryId: String(CategoryConsts.ID_WJ_MUSIC_RECORD)
  },
  meta: {
    title: '洋楽・邦楽レコードの高価買取｜リアリーグッド',
    description: '洋楽・邦楽レコードの買取なら、レコード専門店であり、かつ、洋楽・邦楽レコードに特化したリアリーグッドをご利用ください。'
  }
},
{
  path: '/wj-music-record/article/:articleId',
  name: 'wj-music-record-article',
  component: CategoryArticle,
  props: route => ({
    articleId: String(route.params.articleId)
  }),
  meta: {
    title: '洋楽・邦楽レコードの高価買取｜リアリーグッド',
    description: ''
  }
},
{
  path: '/open-reel',
  name: 'open-reel',
  component: Category,
  props: {
    categoryId: String(CategoryConsts.ID_OPEN_REEL)
  },
  meta: {
    title: 'オープリール・オープンリールデッキの高価買取｜リアリーグッド',
    description: 'オープンリールデッキ・テープの買取なら、リアリーグッドをご利用ください。専門店だからこそできる適切な査定で高価買取を実現しています。'
  }
},
{
  path: '/open-reel/article/:articleId',
  name: 'open-reel-article',
  component: CategoryArticle,
  props: route => ({
    articleId: String(route.params.articleId)
  }),
  meta: {
    title: 'オープリール・オープンリールデッキの高価買取｜リアリーグッド',
    description: ''
  }
},
{
  path: '/audio-cd',
  name: 'audio-cd',
  component: Category,
  props: {
    categoryId: String(CategoryConsts.ID_AUDIO_CD)
  },
  meta: {
    title: '',
    description: ''
  }
},
{
  path: '/audio-cd/article/:articleId',
  name: 'audio-cd-article',
  component: CategoryArticle,
  props: route => ({
    articleId: String(route.params.articleId)
  }),
  meta: {
    title: '',
    description: ''
  }
},
{
  path: '/audio',
  name: 'audio',
  component: Category,
  props: {
    categoryId: String(CategoryConsts.ID_AUDIO)
  },
  meta: {
    title: '',
    description: ''
  }
},
{
  path: '/audio/article/:articleId',
  name: 'audio-article',
  component: CategoryArticle,
  props: route => ({
    articleId: String(route.params.articleId)
  }),
  meta: {
    title: '',
    description: ''
  }
},
{
  path: '/books',
  name: 'books',
  component: Category,
  props: {
    categoryId: String(CategoryConsts.ID_BOOKS)
  },
  meta: {
    title: '',
    description: ''
  }
},
{
  path: '/books/article/:articleId',
  name: 'books-article',
  component: CategoryArticle,
  props: route => ({
    articleId: String(route.params.articleId)
  }),
  meta: {
    title: '',
    description: ''
  }
},
{
  path: '/audio-parts',
  name: 'audio-parts',
  component: Category,
  props: {
    categoryId: String(CategoryConsts.ID_AUDIO_PARTS)
  },
  meta: {
    title: '',
    description: ''
  }
},
{
  path: '/audio-parts/article/:articleId',
  name: 'audio-parts-article',
  component: CategoryArticle,
  props: route => ({
    articleId: String(route.params.articleId)
  }),
  meta: {
    title: '',
    description: ''
  }
},
{
  path: '/others',
  name: 'others',
  component: Category,
  props: {
    categoryId: String(CategoryConsts.ID_OTHERS)
  },
  meta: {
    title: '',
    description: ''
  }
},
{
  path: '/columns',
  name: 'columns',
  component: Columns,
  meta: {
    title: '',
    description: ''
  }
},
{
  path: '/column-detail/:columnId',
  name: 'column-detail',
  component: ColumnDetail,
  props: route => ({
    columnId: String(route.params.columnId)
  }),
  meta: {
    title: '',
    description: ''
  }
},
{
  path: '/others/article/:articleId',
  name: 'others-article',
  component: CategoryArticle,
  props: route => ({
    articleId: String(route.params.articleId)
  }),
  meta: {
    title: '',
    description: ''
  }
},
{
  path: '/all-buyback-records',
  name: 'all-buyback-records',
  component: AllBuybackRecords,
  meta: {
    title: '',
    description: ''
  }
},
// {
//   path: '/newcomer',
//   name: 'newcomer',
//   component: Newcomer,
//   // redirect: '/fl/',
//   meta: {
//     title: '初めての方へ',
//     description: ''//後から挿入
//   }
// },
// {
//   path: '/voice',
//   name: 'voice',
//   component: Voice,
//   // redirect: '/fl/',
//   meta: {
//     title: 'お客さまの声',
//     description: ''//後から挿入
//   }
// },
// {
//   path: '/store_information',
//   name: 'store-information',
//   component: StoreInformation,
//   // redirect: '/fl/',
//   meta: {
//     title: '店舗情報',
//     description: ''//後から挿入
//   }
// },
{
  path: '/privacy',
  name: 'privacy',
  component: Privacy,
  meta: {
    title: '個人情報保護ポリシー',
    description: ''
  }
},
{
  path: '/ordercontract',
  name: 'ordercontract',
  component: Ordercontract,
  meta: {
    title: '特定商取引法に基づく表示',
    description: ''
  }
},
{
  path: '/nagoya',
  name: 'Nagoya',
  component: ShopNagoya,
  meta: {
    title: 'リアリーグッド名古屋店｜レコード/オープンリール/オーディオ買取',
    description: '名古屋市に店舗を構えるレコード・オーディオ買取・販売専門店リアリーグッドでは、クラシックやジャズ、洋楽、邦楽のレコード、オーディオ機器やオープンリールを高価買取強化中です。宅配・出張・店頭持ち込みでの買取が可能です。買取強化エリア：名古屋市、東海市、日進市、長久手市、尾張旭市、瀬戸市、春日井市、小牧市、犬山市、江南市、扶桑町、大口町、一宮市、稲沢市、清須市、北名古屋市、岩倉市、あま市、大治町、津島市、愛西市、弥富市、飛島村、蟹江町、桑名市、四日市市、岐阜市、羽島市、大垣市、多治見市'
  }
},
{
  path: '/okazaki',
  name: 'Okazaki',
  component: ShopOkazaki,
  meta: {
    title: 'リアリーグッド岡崎店｜レコード/オープンリール/オーディオ買取',
    description: '岡崎市に店舗を構えるレコード・オーディオ買取・販売専門店リアリーグッドでは、クラシックやジャズ、洋楽、邦楽のレコード、オーディオ機器やオープンリールを高価買取強化中です。宅配・出張・店頭持ち込みでの買取が可能です。買取強化エリア：安城市、新城市、豊川市、蒲郡市、豊橋市、幸田町、知立市、刈谷市、西尾市、碧南市、高浜市、半田市、田原市、常滑市、知多市、東浦町、大府市、設楽町、豊根村、浜松市'
  }
},
{
  path: '/ctrl',
  // name: 'ctrl',
  component: Ctrl,
  meta: {
    title: '管理画面',
    description: ''
  },
  children: [{
    path: '',
    name: 'ctrl-home',
    component: CtrlHome,
    meta: {
      title: '',
      description: '',
      requiresAuth: true,
      ctrl: true,
    }
  },
  {
    path: 'login',
    name: 'ctrl-login',
    component: CtrlLogin,
    meta: {
      title: 'ログイン',
      description: '',
      requiresAuth: false,
      ctrl: true,
    }
  },
  {
    path: 'change-password',
    name: 'ctrl-change-password',
    component: CtrlChangePassword,
    meta: {
      title: 'パスワード変更',
      description: '',
      requiresAuth: true,
      ctrl: true,
    }
  },
  {
    path: 'select-category/:pageCode',
    name: 'ctrl-select-category',
    component: CtrlSelectCategory,
    props: route => ({
      pageCode: String(route.params.pageCode)
    }),
    meta: {
      title: 'カテゴリー選択',
      description: '',
      requiresAuth: true,
      ctrl: true,
    }
  },
  {
    path: 'group-member',
    name: 'ctrl-group-member',
    component: CtrlGroupMember,
    meta: {
      title: 'カテゴリ選択｜買取強化品目',
      description: '',
      requiresAuth: true,
      ctrl: true
    }
  },
  {
    path: 'group-member/:categoryId',
    name: 'ctrl-group-member-category',
    component: CtrlGroupMemberCategory,
    props: route => ({
      categoryId: String(route.params.categoryId)
    }),
    meta: {
      title: '',
      description: '',
      requiresAuth: true,
      ctrl: true,
    },
    children: [{
      path: 'members',
      name: 'ctrl-group-member-category-members',
      component: CtrlGroupMemberCategoryMembers,
      meta: {
        title: '作成・情報追加・ソート｜買取強化品目',
        description: '',
        // requiresAuth: true,
        ctrl: true,
      }
    },
    {
      path: 'member-detail/:groupId/:memberId',
      name: 'ctrl-group-member-category-member-detail',
      component: CtrlGroupMemberCategoryMemberDetail,
      props: route => ({
        memberId: String(route.params.memberId),
        groupId: String(route.params.groupId)
      }),
      meta: {
        title: '作成・情報追加・ソート 詳細｜買取強化品目',
        description: '',
        // requiresAuth: true,
        ctrl: true,
      }
    },

    ],
  },
  {
    path: 'article',
    name: 'ctrl-article',
    component: CtrlArticle,
    meta: {
      title: 'カテゴリ選択｜記事',
      description: '',
      requiresAuth: true,
      ctrl: true,
    }
  },
  {
    path: 'article/:categoryId',
    name: 'ctrl-article-category',
    component: CtrlArticleCategory,
    props: route => ({
      categoryId: String(route.params.categoryId)
    }),
    meta: {
      title: '',
      description: '',
      requiresAuth: true,
      ctrl: true,
    },
    children: [{
      path: 'articles',
      name: 'ctrl-article-category-articles',
      component: CtrlArticleArticles,
      meta: {
        title: '作成・情報追加・ソート｜記事',
        description: '',
        requiresAuth: true,
        ctrl: true,
      }
    },
    {
      path: 'article-detail/:articleId',
      name: 'ctrl-article-category-article-detail',
      component: CtrlArticleCategoryArticleDetail,
      props: route => ({
        articleId: String(route.params.articleId)
      }),
      meta: {
        title: '作成・情報追加・ソート 詳細｜記事',
        description: '',
        requiresAuth: true,
        ctrl: true,
      }
    },
    ],
  },
  {
    path: 'product',
    name: 'ctrl-product',
    component: CtrlProduct,
    meta: {
      title: 'カテゴリ選択｜商品',
      description: '',
      requiresAuth: true,
      ctrl: true,
    }
  },
  {
    path: 'product/:categoryId',
    name: 'ctrl-product-category',
    component: CtrlProductCategory,
    props: route => ({
      categoryId: String(route.params.categoryId)
    }),
    meta: {
      title: '',
      description: '',
      requiresAuth: true,
      ctrl: true,
    },
    children: [{
      path: 'products',
      name: 'ctrl-product-category-products',
      component: CtrlProductCategoryProducts,
      meta: {
        title: '作成・情報追加・ソート｜記事',
        description: '',
        requiresAuth: true,
        ctrl: true,
      }
    },
    {
      path: 'product-detail/:productId',
      name: 'ctrl-product-category-product-detail',
      component: CtrlProductCategoryProductDetail,
      props: route => ({
        productId: String(route.params.productId)
      }),
      meta: {
        title: '作成・情報追加・ソート 詳細｜記事',
        description: '',
        requiresAuth: true,
        ctrl: true,
      }
    },
    ],
  },
  {
    path: 'results',
    name: 'ctrl-results',
    component: CtrlResults,
    meta: {
      title: '買取実績一覧',
      description: '',
      requiresAuth: true,
      ctrl: true,
    }
  },
  {
    path: 'result-detail/:resultId',
    name: 'ctrl-result-detail',
    component: CtrlResultsDetail,
    props: route => ({
      resultId: String(route.params.resultId)
    }),
    meta: {
      title: '作成・情報追加・ソート 詳細｜買取実績',
      description: '',
      requiresAuth: true,
      ctrl: true,
    }
  },
  {
    path: 'columns/:categoryId',
    name: 'ctrl-columns',
    component: CtrlColumns,
    props: route => ({
      categoryId: String(route.params.categoryId)
    }),
    meta: {
      title: '買取コラム一覧',
      description: '',
      requiresAuth: true,
      ctrl: true,
    }
  },
  {
    path: 'column-detail/:categoryId/:columnId',
    name: 'ctrl-column-detail',
    component: CtrlColumnDetail,
    props: route => ({
      categoryId: String(route.params.categoryId),
      columnId: String(route.params.columnId)
    }),
    meta: {
      title: '買取コラム詳細',
      description: '',
      requiresAuth: true,
      ctrl: true,
    }
  },
  {
    path: 'members',
    name: 'ctrl-members',
    component: CtrlMembers,
    meta: {
      title: '高価買取リスト',
      description: '',
      requiresAuth: true,
      ctrl: true,
    }
  },
  ]
},
  // {
  //   path: '/test',
  //   name: 'test',
  //   component: Test,
  //   meta: {
  //     title: 'test',
  //     description: ''
  //   }
  // }

]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: process.env.NODE_ENV === 'production' ? '/' : process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  // 
  setTitle(to.meta.title);
  setDescription(to.meta.description);

  // 
  store.dispatch("setRootUrl");
  store.dispatch("setGeneralData");
  store.dispatch("setCategoryList");
  store.dispatch("setResultList");
  store.dispatch("setShopList");

  //認証が必要なページかどうか(ログインチェック)
  if (to.matched.some(record => record.meta.requiresAuth) === true) {

    // // 認証状態を確認
    store.dispatch('loginCheck').then((res) => {
      if (res.data.status === 200) {
        store.dispatch('setLogin', 1); //ログインチェック対象のページでのみ、login状態の監視をするようにする
        next();
      } else {
        store.dispatch('setLogin', 0); //ログインチェック対象のページでのみ、login状態の監視をするようにする
        next('/ctrl/login');
      }
    }).catch((err) => {
      console.log(err);
    });

  } else {
    console.log("requiresAuth through.");
    next();
  }
});

export default router
