<template>
  <div class="back-image-title">
    <div class="back-image" :style="`background-image: url(${rootUrl}/img/${topImageFileName});`"></div>
    <div class="article-title">
      <p>
        <span v-html="title"></span>
        <br v-if="subTitle !== null" />
        <span v-if="subTitle !== null">{{ subTitle }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CategoryConsts from "@/consts/CategoryConsts";

export default {
  name: "ui-item-box2",
  components: {},
  props: {
    categoryId: String,
    title: String,
    subTitle: [null, String]
  },
  data() {
    return {
      // Consts
      CategoryConsts: CategoryConsts,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    productData() {
      return this.$store.getters.productData(this.productId);
    },
  },
  created() {
    // 
    let topImageFileName = 'reallygood-pc-top-kobetsu03.jpg';
    switch (this.categoryId) {
      case CategoryConsts.ID_CLASSIC_RECORD:
        topImageFileName = 'reallygood-pc-top-kobetsu03.jpg';
        break;
      case CategoryConsts.ID_JAZZ_RECORD:
        topImageFileName = 'reallygood-pc-top-kobetsu02.jpg';
        break;
      case CategoryConsts.ID_WJ_MUSIC_RECORD:
        topImageFileName = 'reallygood-pc-top-kobetsu04.jpg';
        break;
      case CategoryConsts.ID_OPEN_REEL:
        topImageFileName = 'reallygood-pc-top-kobetsu01.jpg';
        break;

      default:
        break;
    }
    this.topImageFileName = topImageFileName;
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
