<template>
  <div class="home-how-to-buy-delivery">
    <div class="flow">
      <p class="title center">郵送・宅配買取の流れ</p>
      <p class="text">
        全国どこからでも買取します！箱に詰めて送るだけ！送料無料でご自宅
        から発送！梱包キットもご用意しております。
      </p>
      <div class="flow-box">
        <div class="box center-c">
          <img :src="`${rootUrl}/img/mobile-service.png`" alt="出張買取トラック" class="truck" />
          <p>全国どこからでも<br />買取します！</p>
        </div>
        <div class="box center-c">
          <img :src="`${rootUrl}/img/delivery.png`" alt="reallygood-ロゴ" class="box" />
          <p>箱に詰めて<br />送るだけ！</p>
        </div>
        <div class="box center-c">
          <img :src="`${rootUrl}/img/delivery01.png`" class="free" alt="0円" />
          <p>何点からでも<br />送料無料</p>
        </div>
      </div>
    </div>

    <UiBox :type="`orange`" :title="`お電話またはメールでお問い合わせください`" :imgpath="`delivery02.jpg`" :name="``" :text="`全国どこからでも対応いたします。<br>買取専用フリーダイヤル、もしくはメールフォームよりお申込ください。<br><br><span class='big'>・0120-590-510 (受付11:00～19:00)</span><br><br>※受付時間外は留守番電話対応となります。翌営業日に折り返しにて対応させていただきます。<br>
      メールでのお問い合わせは24時間受け付けております。<br>
      買取のご相談やご質問も受け付けておりますので、お気軽にご連絡ください。`" />

    <UiBox :type="`orange`" :title="`ご依頼品を梱包`" :imgpath="`delivery03.jpg`" :name="``"
      :text="`ご依頼品と身分証のコピー(※)、<br>下記の宅配買取申込書を記入し同梱してください。<br><br>梱包材の指定はございませんのでお手持ちの段ボールなどをご利用ください。
      梱包材をお持ちでない方には梱包キットをご用意しておりますので、お申し込み
      の際にお伝えください。<br>梱包の際は輸送事故防止のため、緩衝材などを詰めて中身が動かないようにしてください。<br><br><span class='kome'>※身分証のコピーにつきまして<br>運転免許証、健康保険証、パスポート、学生証、住民票の写しなどの提示をお願いしております。<br>表面、現住所の記載のある面のコピーをご用意ください。</span>`" />
    <div class="pdf">
      <a :href="`${rootUrl}/pdf/REALLYGOOD_1.pdf`" target="_blank" rel="noopener noreferrer">
        <img :src="`${rootUrl}/img/delivery05.jpg`" alt="申込用紙" />
      </a>
      <p>
        申込書の印刷が出来ない場合は、必要事項を手書きのメモ用紙にご記入の上ご同梱下さい。
      </p>
    </div>

    <UiBox :type="`orange`" :title="`ご依頼品を発送`" :imgpath="`delivery06.jpg`" :name="`発送イメージ`"
      :text="`ご都合の良い運送会社へ送料無料(着払い)にてご依頼ください。`" />

    <div class="pdf">
      <img :src="`${rootUrl}/img/delivery07.jpg`" alt="発送伝票イメージ" />
      <p>ゆうパック着払い伝票の記入例</p>
    </div>

    <UiBox :type="`orange`" :title="`査定結果をご連絡`" :imgpath="`delivery08.jpg`" :name="`査定結果イメージ`" :text="`ご依頼品が到着しましたら、専門スタッフが丁寧かつスピーディーに査定させて
いただきます。<br>結果はお電話、メールにてご連絡させていただきます。`" />

    <UiBox :type="`orange`" :title="`ご了承後、お振込でお支払い`" :imgpath="`delivery09.jpg`" :name="`お振込イメージ`"
      :text="`査定額のご了承を頂けましたら、当日、または翌日中にご指定の口座へお振込いたします。`" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBox from "@/components/Ui/Box.vue";
export default {
  name: "home-how-to-buy-delivery",
  components: {
    UiBox,
  },
  props: {},
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-how-to-buy-delivery {
  padding: 0 100px;
  line-height: 1.4em;

  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }

  @media screen and (min-width: 581px) and (max-width: 768px) {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 580px) {
    width: 100%;
  }

  .flow {
    .title {
      color: var(--orange);
      font-size: 26px;
      font-weight: 700;
      padding: 50px 0;

    }
  }

  .flow-box {
    display: flex;
    justify-content: space-between;
    height: 180px;
    margin: 50px 0 100px 0;

    @media screen and (max-width: 768px) {
      // flex-direction: column;
      height: auto;
      margin-top: 40px;
      margin-bottom: 60px;
    }

    .box {
      width: 32%;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 40px;
      }

      img {
        width: 70%;

        @media screen and (max-width: 768px) {
          width: 50%;
          margin-bottom: 10px;
        }

        // margin-bottom: 10px;
      }

      .truck {
        width: 80%;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }

      .box {
        width: 50%;

        @media screen and (max-width: 768px) {
          width: 60%;
        }
      }

      .free {
        @media screen and (max-width: 768px) {
          width: 80%;
        }
      }

      p {
        // line-height: 1.3em;
        font-weight: bold;
        text-align: center;
        font-size: 18px;

        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }

  .pdf {
    padding-bottom: 100px;

    img {
      width: 60%;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    a:hover {
      opacity: 0.7;
    }
  }
}
</style>