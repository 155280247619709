<template>
  <div class="ui-column-list">
    <div class="section-title-cover">
      <div class="section-title">
        <span v-if="categoryId !== '0'">{{ categoryData.name }}の</span>
        <span>買取コラム</span>
      </div>
    </div>
    <div class="column-list-cover">
      <div class="pagination-cover">
        <el-pagination :page-size="pageSize" :pager-count="pagerCount" :total="totalCount"
          :current-page.sync="currentPage" layout="prev, pager, next" />
        <!-- <div>全 {{ totalCount | localeNum }}件</div> -->
      </div>
      <div class="column-list">
        <div v-for="(columnData, index) in columnList" class="column-cover" :key="index">
          <div class="thumbnail-cover">
            <img v-if="columnData.thumbnailPath === null" :src="`${rootUrl}/storage/images/default.jpg`" />
            <img v-else :src="`${rootUrl}/storage/${columnData.thumbnailPath}`" />
          </div>
          <div class="title">{{ columnData.title }}</div>
          <div @click="$router.push(`/column-detail/${columnData.id}`)" class="button">続きを読む</div>
        </div>
      </div>
      <div class="pagination-cover">
        <el-pagination :page-size="pageSize" :pager-count="pagerCount" :total="totalCount"
          :current-page.sync="currentPage" layout="prev, pager, next" />
        <!-- <div>全 {{ totalCount | localeNum }}件</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "column-list",
  components: {},
  props: {
    categoryId: String,
  },
  data() {
    return {
      loading: false,
      pageSize: 8,
      pagerCount: 7,
      totalCount: 0,
      currentPage: 1,
      columnList: []
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    categoryData() {
      return this.$store.getters.categoryData(this.categoryId);
    }
  },
  watch: {
    currentPage(currentPage) {
      this.getColumnList(currentPage);
    }
  },
  created() {
    this.getColumnList(1);
  },
  methods: {
    // 
    getColumnList(currentPage) {
      // 
      this.loading = true;

      // 
      this.currentPage = currentPage;

      // 
      let parameter = ``;
      parameter += `?category_id=${this.categoryId === '0' ? '' : this.categoryId}`;
      parameter += `&visible_only=1`;
      parameter += `&page_size=${this.pageSize}`;
      parameter += `&current_page=${this.currentPage}`;
      this.$axios
        .get(`${this.rootUrl}/api/column/get-list${parameter}`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.totalCount = response.data.totalCount;
            this.columnList = response.data.list;
          } else {
            alert("エラーが発生しました。");
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-column-list {
  padding-bottom: 7em;

  .section-title-cover {
    .section-title {
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .column-list-cover {
    margin-top: 3em;

    .column-list {
      display: flex;
      flex-wrap: wrap;

      .column-cover {
        width: calc(25% - 10px);
        border: solid 2px #999;
        padding: 0.5em;
        margin: 5px;

        @media screen and (max-width: 768px) {
          width: calc(50% - 10px);
        }

        .thumbnail-cover {
          aspect-ratio: 1 / 1;
          display: flex;
          justify-content: center;
          align-items: center;
          object-fit: cover;
          /* この一行を追加するだけ！ */

          img {}
        }

        .title {
          min-height: 5em;
          overflow-wrap: break-word;
          line-height: 1.5em;
        }

        .button {
          cursor: pointer;
          text-align: center;
          background-color: var(--yellow2);
        }

      }
    }
  }
}
</style>
