<template>
  <div class="tinymce-editor">
    <el-button @click="showImageEditor = true" class="image-button" size="medium"><i
        class="el-icon-picture-outline"></i>メディアを追加</el-button>
    <Editor v-model="localText" api-key="jg024mzr4orehm1c1b1y7jfmhqrz1j9oyqz6931bnf8rpirh" :init="{
      height: 500,
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar: 'undo | redo | forecolor backcolor fontsizeselect | bold underline',

      //入力時に改行が<p>にならないように
      force_br_newlines: true,
      force_p_newlines: false,
      forced_root_block: '',

      //テキストからコピペするときに改行が<p>にならないように
      paste_as_text: true
    }" />

    <div class="media-box-back" v-show="showImageEditor === true" @click="close"></div>
    <div class="media-box" v-show="showImageEditor === true">
      <div class="media-box-inner">
        <div class="close" @click="close">×</div>
        <el-tabs type="border-card" @tab-click="switchTab">
          <el-tab-pane label="アップロード">
            <label for="file-upload" v-show="addType === '0'">
              <div v-if="uploadFile === null" class="file-select-label">
                <div>
                  <div class="plus">+</div>
                  <div class="txt">クリックしてファイルを選択</div>
                </div>
              </div>
              <div v-else class="file-select-label2">
                <img :src="imageData" v-show="imageData" style="max-width: 100%; max-height: 100%" />
                <!-- ↓↓ <p>ファイルが選択されました。</p> ↓↓ -->
              </div>
            </label>
            <input class="file-select-input" @change="selectedFile($event)" type="file" accept="image/*"
              id="file-upload" />
            <div v-if="uploadFile !== null" class="btn" v-show="addType === '0'" @click="uploadImage()">
              <el-button type="primary">アップロード</el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane label="画像一覧">
            <div class="img-list">
              <div v-for="(imagePath, index) in imagePathList" :key="index" class="img-box"
                :class="{ 'selected': selectedImageIndex === index }">
                <div v-show="selectedImageIndex === index" class="delete-button" @click="deleteImage(imagePath)">×</div>
                <img @click="selectImage(imagePath, index)" :src="`${rootUrl}/storage/${imagePath}`" />
              </div>
              <div class="img-box-dummy"></div>
              <div class="img-box-dummy"></div>
              <div class="img-box-dummy"></div>
              <div class="img-box-dummy"></div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="btn in" v-show="addType === '1'" @click="appendImage()">
          <div class="white-back">
            <el-button type="primary">画像を挿入</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'TinyMceEditor',
  components: {
    Editor
  },
  props: {
    text: String,
  },
  data() {
    return {
      // 
      addType: '0',
      showImageEditor: false,
      imageEditorTab: 'upload',
      imageData: null,
      uploadFile: null,
      imageSizeLimit: 500_000,
      imagePathList: [],
      selectedImagePath: '',
      selectedImageIndex: null,

      // 
      localText: ''
    }
  },
  computed: {
    ...mapGetters(['rootUrl']),
  },
  watch: {
    text(text) {
      this.localText = text
    },
    localText(text) {
      this.$emit('outputText', text)
    },
  },
  created() {
    this.getImageList();
  },
  methods: {
    close() {
      this.showImageEditor = false;
    },
    switchTab(val, val2) {
      this.addType = val.index;
      console.log(val2);
    },
    appendImage() {
      if (this.selectedImagePath == "") {
        alert("画像を選択してください。");
        return;
      } else {

        // 
        this.text += this.selectedImagePath;

        // 
        this.showImageEditor = false;
      }
    },
    getImageList() {
      this.$axios
        .get(`${this.rootUrl}/api/column/get-image-path-list`)
        .then((response) => {
          console.log(response);
          this.imagePathList = response.data.list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectImage(imagePath, index) {
      // 
      this.selectedImageIndex = index;

      // 
      this.selectedImagePath = `<div><img src="${this.rootUrl}/storage/${imagePath}"></div>`;
    },
    selectedFile(event) {
      // 
      let files = event.target.files;
      this.uploadFile = files[0];
      this.$forceUpdate();

      // 
      const reader = new FileReader();
      reader.onload = (event) => {
        this.imageData = event.target.result;
      };
      reader.readAsDataURL(this.uploadFile);
    },
    uploadImage() {
      if (this.uploadFile === null) {
        alert("ファイルを選択してください。");
        return;
      } else {
        let formData = new FormData();
        formData.append("upload_file", this.uploadFile);
        let config = { headers: { "content-type": "multipart/form-data" } };
        this.$axios
          .post(`${this.rootUrl}/api/column/upload-image`, formData, config)
          .then(response => {
            console.log(response);
            if (response.data.status === 200) {
              alert(
                "アップロードされました。「画像一覧」タブから画像を挿入してください。"
              );
              this.uploadFile = null;
              this.getImageList();
              this.selectedImagePath = "";
            } else if (response.data.status === 413) {
              alert(response.data.message);
            } else {
              alert(this.apiErrorMessage);
            }
            return;
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    deleteImage(imagePath) {
      // 
      let message = ``;
      message = `選択している画像を削除します。よろしいですか？`;

      // 
      if (confirm(message)) {
        const params = new URLSearchParams();
        params.append('image_path', imagePath);
        this.$axios
          .post(`${this.rootUrl}/api/column/delete-image`, params)
          .then((response) => {
            console.log(response);
            alert("削除しました。");
            this.getImageList();
            this.selectedImageIndex = null;
            this.selectedImagePath = "";
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // no action.
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-button {
  margin-bottom: 5px;
}

.media-box-back {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1000;
}

.media-box {
  width: 95%;
  height: 90%;
  background: #ddd;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1001;

  .media-box-inner {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .close {
      position: absolute;
      top: 0.5%;
      right: 1%;
      cursor: pointer;
      font-weight: bold;
      font-size: 24px;
      z-index: 1100;
    }

    .file-select-label,
    .file-select-label2 {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 15%;
      margin: auto;
      width: 50%;
      height: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      color: #ccc;
      font-weight: bold;
      cursor: pointer;

      .plus {
        font-weight: normal;
        text-align: center;
        font-size: 50px;
      }
    }

    .file-select-label {
      border: dashed 3px #ccc;
    }

    .file-select-label2 {
      background: #eee;
      color: white;
      cursor: unset;
    }

    .white-back {
      background-color: white;
      padding: 10px 0;
    }

    .btn {
      text-align: center;
      position: absolute;
      width: 100%;
      z-index: 1100;
      bottom: 10%;
    }

    .in {
      width: 100%;
      bottom: 0;
    }

    ::v-deep.el-tabs {
      width: 100%;
      height: 100%;
      overflow: scroll;

      .el-tabs__content {
        height: calc(100% - 40px);
        overflow: scroll;

        // height: 50vh;
        .el-tab-pane {
          margin-top: 70px;

          .img-list {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;

            .img-box,
            .img-box-dummy {
              width: 300px;
              height: 300px;
              margin: 10px;
            }

            .img-box {
              position: relative;
              background: #ddd;
              cursor: pointer;

              &.selected {
                border: solid 5px #409eff;
              }

              .delete-button {
                position: absolute;
                top: -4%;
                right: -4%;
                width: 25px;
                height: 25px;
                background: red;
                border-radius: 50%;
                z-index: 1050;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
              }

              img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: auto;
                width: auto;
                max-width: 100%;
                max-height: 100%;
                margin: auto;
              }
            }
          }

          input.file-select-input {
            display: none;
          }
        }
      }
    }
  }
}
</style>